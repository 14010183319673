export const pluralize = (n: number, one: string, few: string, many: string): string => {
    const nP100 = n % 100;
    const nP10 = n % 10;

    if (nP10 === 1 && nP100 !== 11) {
        return one;
    }
    if (nP10 >= 2 && nP10 <= 4 && (nP100 < 10 || nP100 >= 20)) {
        return few;
    }
    return many;
};
