export const FORM_FIELDS_WORK = {
    EMPLOYMENT_TYPE: 'employmentType',
    ORGANIZATION_NAME: 'organizationName',
    WORK_ADDRESS: 'workAddress',
    WORK_ROUTE: 'workLocationRoute',
    WORK_PHONE: 'workPhone',
    LAST_EXPERIENCE: 'lastExperience',
    LAST_EXPERIENCE_START_MONTH: 'lastExperienceStartMonth',
    LAST_EXPERIENCE_START_YEAR: 'lastExperienceStartYear',
    AREA_OF_EMPLOYMENT: 'areaOfEmployment',
    JOB_TITLE: 'jobTitle',
    JOB_TYPE: 'jobType',
    EMPLOYER_INN: 'employerINN',
    EMPLOYER_TYPE: 'employerType',
    SENIORITY: 'seniority',
    UNEMPLOYMENT_REASON: 'unemploymentReason',
    ANOTHER_UNEMPLOYMENT_REASON: 'anotherUnemploymentReason', // unemployment_reason_other
} as const;

export const FORM_FIELDS_PLEDGE_REALTY = {
    PLEDGE_REALTY_ADDRESS_THE_SAME: 'pledgeRealtyAddressTheSame',
    PLEDGE_REALTY_STREET: 'pledgeRealtyStreet',
    PLEDGE_REALTY_HOUSE: 'pledgeRealtyHouse',
    PLEDGE_REALTY_STRUCTURE: 'pledgeRealtyStructure',
    PLEDGE_REALTY_BUILDING: 'pledgeRealtyBuilding',
    PLEDGE_REALTY_FLAT: 'pledgeRealtyFlat',
    PLEDGE_OWNER_SINGLE: 'pledgeOwnerSingle',
    PLEDGE_OWNER_MYSELF: 'pledgeOwnerMyself',
    PLEDGE_OWNER_FIO: 'pledgeOwnerFio',
    PLEDGE_OWNER_FIRST_NAME: 'pledgeOwnerFirstName',
    PLEDGE_OWNER_LAST_NAME: 'pledgeOwnerLastName',
    PLEDGE_OWNER_MIDDLE_NAME: 'pledgeOwnerMiddleName',
    PLEDGE_OWNER_PHONE: 'pledgeOwnerPhone',
    PLEDGE_OWNER_FAMILY_STATUS: 'pledgeOwnerFamilyStatus',
    PLEDGE_OWNER_RELATIONSHIP: 'pledgeOwnerRelationship',
} as const;

const FORM_FIELDS_PLEDGE_AUTO = {
    PLEDGE_AUTO_NUMBER: 'pledgeAutoNumber',
    PLEDGE_AUTO_BRAND: 'pledgeAutoBrand',
    PLEDGE_AUTO_BRAND_ID: 'pledgeAutoBrandId',
    PLEDGE_AUTO_MODEL: 'pledgeAutoModel',
    PLEDGE_AUTO_MODEL_ID: 'pledgeAutoModelId',
    PLEDGE_AUTO_YEAR: 'pledgeAutoYear',
    PLEDGE_AUTO_POWER: 'pledgeAutoPower',
    PLEDGE_AUTO_OWNER_MYSELF: 'pledgeAutoOwnerMyself',
} as const;

/** CF-1309 */
export const ADDITIONAL_QUESTIONS_FIELDS = {
    WHEN_MONEY_NEEDED: 'whenMoneyNeeded',
    HAS_RECENT_APPLICATION: 'hasRecentApplication',
    RECENT_APPLICATION_CAUSE: 'recentApplicationCause',
} as const;

export const FORM_FIELDS = {
    AMOUNT: 'amount',
    INITIAL_FEE: 'initialFee',
    PERIOD: 'period',
    REGION: 'region',
    REGION_FULL_NAME: 'regionFullName',
    REGION_ID: 'regionId',
    REGION_LOCATION_ROUTE: 'locationRoute',
    KLADR_ID: 'kladrId',
    MONTHLY_SALARY: 'monthlySalary',
    INCOME_CONFIRMATION: 'incomeConfirmation',
    CURRENCY: 'currency',
    PURPOSE: 'purpose',
    PRODUCT_TYPES: 'productTypes',
    PASSWORD: 'password',
    SMS_CODE: 'smsCode',
    SMS_CODE_DATE: 'smsCodeDate',
    CALL_NUMBERS: 'callNumbers',
    IS_CALL_CENTER: 'isCallCenter',
    IS_CALL_CENTER_EDITABLE: 'isCallCenterEditable',
    /** @depreсated */
    IS_RESTORE: 'isRestore',
    AUTO_MODEL: 'autoModel',
    AUTO_MODEL_ID: 'autoModelId',
    AUTO_BRAND: 'autoBrand',
    AUTO_BRAND_ID: 'autoBrandId',
    AUTO_YEAR: 'autoYear',

    OKB_SCORE: 'okbScore',
    OKB_YANDEX_SCORE: 'okbYandexScore',
    SCORE_GEN7_CC: 'scoreGen7cc',
    SCORE_GEN7_CL: 'scoreGen7cl',
    SCORE_GEN7_CL_HIT: 'scoreGen7clHit',
    SRAVNI_SCORE: 'sravniScore',
    /** @deprecated больше не используется */
    NBKI_SCORE: 'nbkiScore',

    CREDIT_SECURITY: 'creditSecurity',
    AREA_APARTMENT: 'areaApartment',
    PROPERTY_TYPE: 'propertyType',

    FULL_NAME: 'fullName',
    LAST_NAME: 'lastName',
    FIRST_NAME: 'firstName',
    MIDDLE_NAME: 'surname',
    PHONE: 'phone',
    // PHONE_IDENTITY_DISABLED: 'phoneIdentityDisabled',
    ADDITIONAL_PHONE: 'additionalPhone',
    ADDITIONAL_PHONE_OWNER: 'additionalPhoneOwner',
    ADDITIONAL_PHONE_OWNER_FIO: 'additionalPhoneOwnerFio',
    EMAIL: 'email',
    AGREEMENT: 'agreement',
    GENDER: 'gender',

    ...FORM_FIELDS_WORK,
    /** @deprecated not used */
    SALARY_BANK: 'salaryBank',

    BIRTHDAY: 'birthDate',
    BIRTH_PLACE: 'birthPlace',
    PASSPORT_NUMBER: 'sn',
    PASSPORT_DATE: 'issueDate',
    UNIT_CODE: 'code',
    ISSUED: 'issuer',
    REGISTRATION: 'address',

    REGISTRATION_DADATA_FULL: 'registrationDadataFull',
    REGISTRATION_STREET: 'registrationStreet',
    REGISTRATION_STREET_VALIDATION: 'registrationStreetValidation',
    REGISTRATION_APARTMENT: 'registrationApartment',
    REGISTRATION_DATE: 'registrationDate',
    REGISTRATION_ROUTE: 'registrationLocationRoute',

    RESIDENCE_SAME_REGISTRATION: 'residenceSameRegistration',

    RESIDENCE: 'residence',
    RESIDENCE_DADATA_FULL: 'residenceDadataFull',
    RESIDENCE_STREET: 'residenceStreet',
    RESIDENCE_STREET_VALIDATION: 'residenceStreetValidation',
    RESIDENCE_APARTMENT: 'residenceApartment',
    RESIDENCE_ROUTE: 'residentialLocationRoute',
    RESIDENCE_REGION: 'residenceRegion',
    RESIDENCE_CITY: 'residenceCity',
    RESIDENCE_STR: 'residenceStr',
    RESIDENCE_BLOCK: 'residenceBlock',

    REGISTRATION_REGION: 'registrationRegion',
    REGISTRATION_CITY: 'registrationCity',
    REGISTRATION_STR: 'registrationStr',
    REGISTRATION_BLOCK: 'registrationBlock',

    PLEDGE_REGION: 'pledgeRegion',
    PLEDGE_CITY: 'pledgeCity',
    PLEDGE_STR: 'pledgeStr',
    PLEDGE_BLOCK: 'pledgeBlock',
    PLEDGE_DADATA_FULL: 'pledgeDadataFull',

    EDUCATION: 'education',
    FAMILY_STATUS: 'familyStatus',
    CREDIT_HISTORY: 'creditHistory',
    SNILS: 'snils',
    CAR: 'car',
    CAR_BRAND: 'carBrand',
    CAR_MODEL: 'carModel',
    CAR_YEAR: 'carYear',
    CAR_PRICE: 'carPrice',
    RENT_APARTMENT: 'rentApartment',
    NUMBER_OF_DEPENDENTS: 'dependents',
    ADDITIONAL_INCOME: 'additionalIncome',
    DELIVERY_TYPE: 'deliveryType',
    FILTERED_ORGANIZATION_ID: 'filteredOrganizationId',
    TINKOFF_REQUEST_SENT: 'tinkoffRequestSent',
    ...FORM_FIELDS_PLEDGE_REALTY,
    ...FORM_FIELDS_PLEDGE_AUTO,

    PARTNER_ID: 'partnerId',
    OKVED: 'okved',

    ...ADDITIONAL_QUESTIONS_FIELDS,
    FORCE_UPDATE: 'forceUpdate', // Для принудительного обновления данных - any string like uuid4()
} as const;

export const FORM_FIELDS_PHONES = [
    FORM_FIELDS.PHONE,
    FORM_FIELDS.WORK_PHONE,
    FORM_FIELDS.ADDITIONAL_PHONE,
    FORM_FIELDS.PLEDGE_OWNER_PHONE,
    // FORM_FIELDS.PHONE_IDENTITY_DISABLED,
];

export const FORM_FIELDS_LABELS_CONFIG = {
    [FORM_FIELDS.AMOUNT]: { text: 'Сумма кредита', required: true },
    [FORM_FIELDS.INITIAL_FEE]: { text: 'Сумма личных средств', required: true },
    [FORM_FIELDS.PERIOD]: { text: 'На какой срок', required: true },
    [FORM_FIELDS.REGION]: { text: 'Город получения кредита', required: true },
    [FORM_FIELDS.PURPOSE]: { text: 'Цель кредита', required: true },
    [FORM_FIELDS.AUTO_MODEL]: { text: 'Модель автомобиля', required: true },
    [FORM_FIELDS.AUTO_BRAND]: { text: 'Марка автомобиля', required: true },
    [FORM_FIELDS.AUTO_YEAR]: { text: 'Примерный год выпуска автомобиля', required: true },

    [FORM_FIELDS.FULL_NAME]: { text: 'ФИО', required: true },
    [FORM_FIELDS.LAST_NAME]: { text: 'Фамилия', required: true },
    [FORM_FIELDS.FIRST_NAME]: { text: 'Имя', required: true },
    [FORM_FIELDS.MIDDLE_NAME]: { text: 'Отчество', required: true },
    [FORM_FIELDS.PHONE]: { text: 'Номер телефона', required: true },
    [FORM_FIELDS.EMAIL]: { text: 'Электронная почта', required: true },
    [FORM_FIELDS.PASSWORD]: { text: 'Введите пароль', required: true },
    [FORM_FIELDS.SMS_CODE]: { text: 'Код подтверждения', required: true },
    [FORM_FIELDS.CALL_NUMBERS]: { text: 'Последние 4 цифры номера', required: true },

    [FORM_FIELDS.BIRTHDAY]: { text: 'Дата рождения', required: true },
    [FORM_FIELDS.BIRTH_PLACE]: { text: 'Место рождения', required: true },
    [FORM_FIELDS.PASSPORT_NUMBER]: { text: 'Серия и номер паспорта', required: true },
    [FORM_FIELDS.PASSPORT_DATE]: { text: 'Дата выдачи', required: true },
    [FORM_FIELDS.UNIT_CODE]: { text: 'Код подразделения', required: true },
    [FORM_FIELDS.ISSUED]: { text: 'Кем выдан', required: true },
    [FORM_FIELDS.REGISTRATION_STREET]: { text: 'Населенный пункт, улица, дом', required: true },
    [FORM_FIELDS.REGISTRATION_APARTMENT]: { text: 'Квартира' },
    [FORM_FIELDS.REGISTRATION_DATE]: { text: 'Дата регистрации', required: true },
    [FORM_FIELDS.RESIDENCE_SAME_REGISTRATION]: {
        text: 'Адрес фактического проживания совпадает с адресом регистрации',
        withFlag: false,
    },
    [FORM_FIELDS.RESIDENCE_STREET]: { text: 'Населенный пункт, улица, дом', required: true },
    [FORM_FIELDS.RESIDENCE_APARTMENT]: { text: 'Квартира' },

    [FORM_FIELDS.EMPLOYMENT_TYPE]: { text: 'Тип занятости', required: true },
    [FORM_FIELDS.ORGANIZATION_NAME]: { text: 'Название организации', required: true },
    [FORM_FIELDS.EMPLOYER_INN]: { text: 'ИНН организации', required: false },
    [FORM_FIELDS.EMPLOYER_TYPE]: { text: 'Тип организации', required: true },
    [FORM_FIELDS.AREA_OF_EMPLOYMENT]: { text: 'Сфера деятельности', required: true },
    [FORM_FIELDS.LAST_EXPERIENCE]: { text: 'Начало работы на последнем месте', required: true },
    [FORM_FIELDS.JOB_TITLE]: { text: 'Название должности', required: true },
    [FORM_FIELDS.JOB_TYPE]: { text: 'Тип должности', required: true },
    [FORM_FIELDS.WORK_ADDRESS]: { text: 'Рабочий адрес', required: true },
    [FORM_FIELDS.WORK_PHONE]: { text: 'Рабочий телефон', required: true },
    [FORM_FIELDS.MONTHLY_SALARY]: { text: 'Ежемесячный доход (на руки)', required: true },
    [FORM_FIELDS.INCOME_CONFIRMATION]: { text: 'Способ подтверждения дохода', required: true },
    [FORM_FIELDS.UNEMPLOYMENT_REASON]: { text: 'Причина отсутствия занятости', required: true },
    [FORM_FIELDS.ANOTHER_UNEMPLOYMENT_REASON]: { text: 'Другая причина', required: true },
    [FORM_FIELDS.LAST_EXPERIENCE_START_MONTH]: { text: 'Месяц', required: true },
    [FORM_FIELDS.LAST_EXPERIENCE_START_YEAR]: { text: 'Год', required: true },

    [FORM_FIELDS.CAR_BRAND]: { text: 'Марка', required: true },
    [FORM_FIELDS.CAR_MODEL]: { text: 'Модель', required: true },
    [FORM_FIELDS.CAR_YEAR]: { text: 'Год выпуска', required: true },
    [FORM_FIELDS.ADDITIONAL_PHONE]: { text: 'Дополнительный телефон', required: true },
    [FORM_FIELDS.ADDITIONAL_PHONE_OWNER]: { text: 'Владелец телефона', required: true },
    [FORM_FIELDS.ADDITIONAL_PHONE_OWNER_FIO]: { text: 'ФИО владельца доп.номера', required: true },
    [FORM_FIELDS.EDUCATION]: { text: 'Образование', required: true },
    [FORM_FIELDS.FAMILY_STATUS]: { text: 'Семейное положение', required: true },
    [FORM_FIELDS.NUMBER_OF_DEPENDENTS]: { text: 'Дети до 18 лет' },
    [FORM_FIELDS.ADDITIONAL_INCOME]: { text: 'Доп. доход' },
    [FORM_FIELDS.CAR]: { text: 'Наличие автомобиля' },
    [FORM_FIELDS.RENT_APARTMENT]: { text: 'Платежи по кредитам' },
    [FORM_FIELDS.CREDIT_HISTORY]: { text: 'Кредитная история' },
    [FORM_FIELDS.SNILS]: { text: 'СНИЛС' },
    [FORM_FIELDS.PROPERTY_TYPE]: { text: 'Наличие недвижимости' },
    [FORM_FIELDS.CREDIT_SECURITY]: { text: 'Залог', required: true },

    [FORM_FIELDS.PLEDGE_REALTY_ADDRESS_THE_SAME]: { text: 'Совпадает с адресом регистрации', required: true },
    [FORM_FIELDS.PLEDGE_REALTY_STREET]: { text: 'Населенный пункт, улица, дом', required: true },
    [FORM_FIELDS.PLEDGE_REALTY_HOUSE]: { text: 'Дом', required: true },
    [FORM_FIELDS.PLEDGE_REALTY_STRUCTURE]: { text: 'Корпус', required: true },
    [FORM_FIELDS.PLEDGE_REALTY_BUILDING]: { text: 'Строение', required: true },
    [FORM_FIELDS.PLEDGE_REALTY_FLAT]: { text: 'Квартира', required: true },
    [FORM_FIELDS.PLEDGE_OWNER_SINGLE]: { text: 'Я единственный собственник', required: true },
    [FORM_FIELDS.PLEDGE_OWNER_MYSELF]: { text: 'Являюсь собственником', required: true },
    [FORM_FIELDS.PLEDGE_OWNER_FIO]: { text: 'Фамилия, имя и отчество', required: true },
    [FORM_FIELDS.PLEDGE_OWNER_PHONE]: { text: 'Мобильный телефон', required: true },
    [FORM_FIELDS.PLEDGE_OWNER_FIRST_NAME]: { text: 'Имя', required: true },
    [FORM_FIELDS.PLEDGE_OWNER_LAST_NAME]: { text: 'Фамилия', required: true },
    [FORM_FIELDS.PLEDGE_OWNER_MIDDLE_NAME]: { text: 'Отчество', required: true },
    [FORM_FIELDS.PLEDGE_OWNER_FAMILY_STATUS]: { text: 'Семейное положение', required: true },
    [FORM_FIELDS.PLEDGE_OWNER_RELATIONSHIP]: { text: 'Принадлежность к заёмщику', required: true },

    [FORM_FIELDS.PLEDGE_AUTO_NUMBER]: { text: 'Гос. номер', withFlag: true },
    [FORM_FIELDS.PLEDGE_AUTO_BRAND]: { text: 'Марка', required: true },
    [FORM_FIELDS.PLEDGE_AUTO_BRAND_ID]: { text: 'Марка', required: true },
    [FORM_FIELDS.PLEDGE_AUTO_MODEL]: { text: 'Модель', required: true },
    [FORM_FIELDS.PLEDGE_AUTO_MODEL_ID]: { text: 'Модель', required: true },
    [FORM_FIELDS.PLEDGE_AUTO_YEAR]: { text: 'Год выпуска', required: true },
    [FORM_FIELDS.PLEDGE_AUTO_POWER]: { text: 'Мощность', required: true },

    [FORM_FIELDS.SENIORITY]: { text: 'Общий трудовой стаж' },
};

export const LABELS = Object.keys(FORM_FIELDS_LABELS_CONFIG).reduce(
    (source, key) => {
        const { text, required, withFlag } = FORM_FIELDS_LABELS_CONFIG[key];
        source[key] = text;
        if (!required && withFlag !== false) {
            source[key] += ' (необязат.)';
        }
        return source;
    },
    {} as { [key: string]: string },
);
