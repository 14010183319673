import { useSelector } from 'react-redux';
import { createAction, createReducer } from 'redux-act';
import { IGlobalState } from '..';
import { SelectionDictionaries } from '../../services/offers/dictionaries';

interface IState {
    organizations: Record<string, SelectionDictionaries.TOrganization>;
    products: Record<string, SelectionDictionaries.TProduct>;
    mfoStatuses: Record<string, SelectionDictionaries.TMFOStatus>;
}

const initialState: IState = {
    organizations: {},
    products: {},
    mfoStatuses: {},
};

export const setDictionaries = createAction<IState>('set selection dictionaries');
export const extendDictionaries = createAction<Partial<IState>>('extend selection dictionaries');

export const selectionDictionariesReducer = createReducer<IState>({}, initialState)
    .on(setDictionaries, (_, payload) => payload)
    .on(extendDictionaries, (state, payload) => {
        Object.entries(payload).forEach(([key, value]) => {
            if (value) {
                state[key] = { ...state[key], ...value };
            }
        });
        return state;
    });

export const useSelectionDictionaryProducts = () =>
    useSelector((state: IGlobalState) => state.selectionDictionaries.products);

export const useSelectionDictionaryOrganizations = () =>
    useSelector((state: IGlobalState) => state.selectionDictionaries.organizations);

export const useSelectionDictionaryMfoStatuses = () =>
    useSelector((state: IGlobalState) => state.selectionDictionaries.mfoStatuses);
