import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import getConfig from 'next/config';
import { IGlobalState } from '../index';
import routes from '../../constants/routes.internal.json';

/** param abGroup removed, use abData reducer instead */
interface State {
    /** Активен ли функционал маркетплейса */
    mplIsActive: boolean;
    /** Публичный хост маркетплейса. Возможность установить, чтобы обрабатывать несколько тестовых стендов */
    mplPublic: string;
    cabinetPublic: string;
    page: keyof typeof routes;
    legalFooter: boolean;
    mainSkeleton: number;
    mainSkeletonIsActive: boolean;
    /** @deprecated */
    shortSkeleton: boolean;
    /** Отображать ли продукты при невозможности определить скоринг */
    nullScoreProducts: boolean;
    /** Скрывать ли баннер установки мобильного приложения */
    disableMobileAppBanner: boolean;
    /** https://sravni-corp.atlassian.net/browse/CF-1880 */
    hasMultipleTabsFeature: boolean;
}

export const setSelection = createAction<Partial<State>>('set selection');

export const disableLegalFooter = createAction('set legalFooter');

/** Для кооректного отображения количество вызовов show и hide должно совпадать */
export const showMainSkeleton = createAction<{ shortSkeleton?: boolean }>('show main skeleton');

export const hideMainSkeleton = createAction('hide main skeleton');

export const setMainSkeletonIsActive = createAction<boolean>('set main skeleton is active');

export const useSelection = () => useSelector((state: IGlobalState) => state.selection as State);

export const useMainSkeleton = () =>
    useSelector(
        ({ selection }: IGlobalState) => ({
            showMainSkeleton: selection.mainSkeleton > 0,
            shortSkeleton: selection.shortSkeleton,
            mainSkeletonIsActive: selection.mainSkeletonIsActive,
        }),
        isEqual,
    );

export const selectionReducer = createReducer<State>(
    {},
    {
        mplPublic: getConfig()?.publicRuntimeConfig.mplPublic,
        cabinetPublic: getConfig()?.publicRuntimeConfig.cabinetPublic,
        mplIsActive: false,
        page: 'landing',
        legalFooter: true,
        mainSkeletonIsActive: false,
        mainSkeleton: 0,
        shortSkeleton: false,
        nullScoreProducts: getConfig()?.publicRuntimeConfig.nullScoreProducts,
        disableMobileAppBanner: false,
        hasMultipleTabsFeature: false,
    },
)
    .on(setSelection, (state, data) => ({ ...state, ...data }))
    .on(disableLegalFooter, (state) => ({ ...state, legalFooter: false }))
    .on(showMainSkeleton, (state, data = {}) => ({
        ...state,
        mainSkeleton: state.mainSkeleton + 1,
        shortSkeleton: data.shortSkeleton ?? false,
    }))
    .on(hideMainSkeleton, (state) => ({ ...state, mainSkeleton: state.mainSkeleton - 1 }))
    .on(setMainSkeletonIsActive, (state, payload) => ({ ...state, mainSkeletonIsActive: payload }));
