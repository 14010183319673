import { differenceInDays, differenceInYears, format as dateFormat, parse, isValid as dateIsValid } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import RuLocale from 'date-fns/locale/ru';
import { parse as parse8601 } from 'iso8601-duration';
import { pluralize } from '../pluralize';

/**
 * Преобразует строку даты в метку времени
 * @example "14.07.2020" => 1594684800000
 */
export const dateStringToTimestamp = (dateString: string) => {
    const dayMonthYear = dateString.split('.').map((i) => parseInt(i, 10));
    dayMonthYear[1]--;
    // @ts-ignore
    return new Date(...dayMonthYear.reverse()).getTime();
};

/**
 * Разница в годах между двумя датами
 * @example new Date(2020, 6, 14), new Date(2010, 6, 14) => 10
 */
export const dateDiffYears = (a: Date, b: Date) => {
    return differenceInYears(a, b);
};

/**
 * Разница в днях между двумя датами
 * @example new Date(2020, 6, 14), new Date(2020, 6, 10) => 4
 */
export const dateDiffDays = (a: Date, b: Date) => {
    return differenceInDays(a, b);
};

/**
 * Разница в месяцах между двумя датами
 * @example new Date(2020, 6, 14), new Date(2019, 6, 14) => 12
 */
export const dateDiffMonths = (a: Date, b: Date) => {
    const ageDate = new Date(Math.abs(a.getTime() - b.getTime()));
    return Math.abs(ageDate.getUTCFullYear() - 1970) * 12 + ageDate.getUTCMonth();
};

/**
 * Проверяет, является ли период строкой в формате ISO 8601
 * @example "P1Y" => true
 */
export const validatePeriod = (period: unknown): period is string => {
    return typeof period === 'string' ? /P\d+[YMD]$/.test(period) : false;
};

/** 12 => 'P1Y' */
export const numberToPeriod = (value: number, period: 'd' | 'm' | 'y' = 'm') => {
    const daysValue = value * ({ d: 1, m: 30, y: 360 }[period] || 1);
    const years = Math.trunc(daysValue / 360);
    const months = Math.trunc((daysValue % 360) / 30);
    const days = (daysValue % 360) % 30;
    return 'P' + (years ? `${years}Y` : '') + (months ? `${months}M` : '') + (days ? `${days}D` : '');
};

const convertDateToUTCString = (date: Date, timezone: string) => {
    const dateWithoutTimezone = zonedTimeToUtc(date, timezone);
    return dateWithoutTimezone.toISOString();
};

/**
 * Преобразует строку даты в метку времени с учетом часового пояса
 * @example "14.07.2020 12:00", "Europe/Moscow" => 1594713600000
 */
export const dateStringToLocalTs = (dateString: string, dateTimeZone?: string) => {
    if (!dateString) {
        return Date.now();
    }
    const [date, time] = dateString.split(' ');
    const [day, month, year] = date.split('.').map((i) => parseInt(i, 10));
    const dateFromString = new Date(year, month - 1, day, ...time.split(':').map((i) => parseInt(i, 10)));

    return dateTimeZone
        ? new Date(convertDateToUTCString(dateFromString, dateTimeZone)).getTime()
        : dateFromString.getTime();
};

/** День недели в текстовом формате */
export const getWeekDay = (date: Date) => {
    return dateFormat(date, 'eeee', { locale: RuLocale });
};

/**
 * Преобразует дату в формат формы
 * @example "2020-07-14" => "14.07.2020"
 */
export const dateToFormFormat = (date?: string) => {
    if (!date) {
        return undefined;
    }

    if (date.length == 10 && date.includes('.') && date.split('.')[2].length == 4) {
        return date;
    }

    if (date.length == 10 && date.includes('-') && date.split('-')[0].length == 4) {
        return date.split('-').reverse().join('.');
    }

    const d = new Date(date);
    const day = d.getDate();
    const month = d.getMonth() + 1;
    return [day > 9 ? day : `0${day}`, month > 9 ? month : `0${month}`, d.getFullYear()].join('.');
};

/** Дата и месяц в текстовом формате */
export const getDateMonth = (date: Date, output = 'dd MMM') => {
    return dateFormat(date, output, { locale: RuLocale });
};

/** "14.07.2020" => 1988-07-14T00:00:00.000Z */
export const parseZonedDate = (date: string, formatString = 'dd.MM.yyyy') => {
    const localDate = parse(date, formatString, new Date());
    return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60 * 1000);
};

/**
 * ISO 8601 DURATION в количество дней
 * @example "P1M" => 30
 */
export const durationToDays = (isoDuration: string, { daysInMonth = 30, daysInYears = 365 } = {}): number => {
    try {
        const { years = 0, months = 0, weeks = 0, days = 0 } = parse8601(isoDuration);
        return years * daysInYears + months * daysInMonth + weeks * 7 + days;
    } catch {
        return 0;
    }
};

/**
 * ISO 8601 DURATION в количество месяцев
 * @example "P5Y" => 60
 */
export const durationToMonths = (isoDuration: string, { daysInMonth = 30, daysInYears = 365 } = {}): number => {
    try {
        return Math.floor(durationToDays(isoDuration, { daysInMonth, daysInYears }) / daysInMonth);
    } catch {
        return 0;
    }
};

/** "14.07.2020" => 1988-07-14T00:00:00.000Z */
export const parseFromHumanToIsoDate = (humanDateStr: string) => {
    const parsedDate = parse(humanDateStr, 'dd.MM.yyyy', new Date());

    return dateIsValid(parsedDate) ? parsedDate : null;
};

export const pluralizeDays = (days: number, isMany = false) =>
    // @ts-ignore
    pluralize(days, ...['день', 'дня', 'дней', 'дней'].slice(isMany ? 1 : 0));

export const pluralizeMonths = (months: number, isMany = false) =>
    // @ts-ignore
    pluralize(months, ...['месяц', 'месяца', 'месяцев', 'месяцев'].slice(isMany ? 1 : 0));

export const pluralizeYears = (years: number, isMany = false) =>
    // @ts-ignore
    pluralize(years, ...['год', 'года', 'лет', 'лет'].slice(isMany ? 1 : 0));

export const pluralizeUnit = (value: string): string => {
    try {
        const { years = 0, months = 0, days = 0 } = parse8601(value);

        const yearString = years ? `${years} ${pluralizeYears(years)}` : '';
        const monthString = months ? `${months} ${pluralizeMonths(months)}` : '';
        const dayString = days ? `${days} ${pluralizeDays(days)}` : '';

        return [yearString, monthString, dayString].filter(Boolean).join(' ');
    } catch {
        return '';
    }
};

/** 60 => "5 лет" */
export const pluralizeMonthPeriod = (monthCount: number, isMany?: boolean) => {
    const years = Math.trunc(monthCount / 12);
    const months = monthCount % 12;
    const yearsPart = years ? `${years} ${pluralizeYears(years, isMany)}` : '';
    const monthsPart = months ? `${months} ${pluralizeMonths(months, isMany)}` : '';
    return [yearsPart, monthsPart].filter((s) => s).join(' ');
};
