import { FORM_FIELDS } from './form.constants';
import { BANKS_ID } from './banks';
import { CURRENCY } from 'services/interfaces';

export * from './form.constants';
export * from './formbuilder.constants';
export * from './regexp.constants';
export * from './profile.constants';
export * from './banks';
export * from './experiments.constants';
export * from './pages.constants';
export * from './queryParams.constants';
export * from './headers.constants';
export * from './utm.constants';

export const BANK_DECISION_TYPES = {
    WITH_INSURANCE: 'REQUESTED_VALUE_WITH_INSURANCE',
    WITHOUT_INSURANCE: 'REQUESTED_VALUE_WITHOUT_INSURANCE',
    MAXSUM_WITH_INSURANCE: 'MAXSUM_WITH_INSURANCE',
    MAXSUM_WITHOUT_INSURANCE: 'MAXSUM_WITHOUT_INSURANCE',
};

export const FORM_NAME = 'CreditOnlineV3';
export const FILTERS_ANCHOR_ELEMENT_ID = 'FiltersAnchorId';

export const DEFAULT_TERMS = 36;
export const DEFAULT_AMOUNT = 300_000;
export const AMOUNT_LIMITS = {
    MAX: 15e6,
    MIN: 1000,
    STEP: 10000,
};

export const INITIAL_FEE_LIMITS = {
    MAX: 15e6,
    MIN: 0,
};

export const STATUS_COUNTDOWN = {
    LONG: 120,
    SHORT: 30,
};

export const FOOTER_RATES = {
    min: 9,
    max: 40,
};

export const WAYBACK_FIELDS_MAP = {
    personalDataConsent: FORM_FIELDS.AGREEMENT,
    amount: FORM_FIELDS.AMOUNT,
    initialFee: FORM_FIELDS.INITIAL_FEE,
    term: FORM_FIELDS.PERIOD,
    purpose: FORM_FIELDS.PURPOSE,
    solvencyProof: FORM_FIELDS.INCOME_CONFIRMATION,
    firstName: FORM_FIELDS.FIRST_NAME,
    middleName: FORM_FIELDS.MIDDLE_NAME,
    lastName: FORM_FIELDS.LAST_NAME,
    email: FORM_FIELDS.EMAIL,
    phone: FORM_FIELDS.PHONE,
    additionalPhone: FORM_FIELDS.ADDITIONAL_PHONE,
    additionalPhoneOwner: FORM_FIELDS.ADDITIONAL_PHONE_OWNER,
    additionalPhoneOwnerFio: FORM_FIELDS.ADDITIONAL_PHONE_OWNER_FIO,
    birthDate: FORM_FIELDS.BIRTHDAY,
    birthPlace: FORM_FIELDS.BIRTH_PLACE,
    passportNumber: FORM_FIELDS.PASSPORT_NUMBER,
    passportIssueDate: FORM_FIELDS.PASSPORT_DATE,
    passportIssuedBy: FORM_FIELDS.ISSUED,
    passportIssuedByCode: FORM_FIELDS.UNIT_CODE,
    registrationDate: FORM_FIELDS.REGISTRATION_DATE,
    maritalStatus: FORM_FIELDS.FAMILY_STATUS,
    education: FORM_FIELDS.EDUCATION,
    snils: FORM_FIELDS.SNILS,
    salaryBank: FORM_FIELDS.SALARY_BANK,
    employmentType: FORM_FIELDS.EMPLOYMENT_TYPE,
    organizationName: FORM_FIELDS.ORGANIZATION_NAME,
    organizationInn: FORM_FIELDS.EMPLOYER_INN,
    organizationType: FORM_FIELDS.EMPLOYER_TYPE,
    organizationActivity: FORM_FIELDS.AREA_OF_EMPLOYMENT,
    lastExperienceStartDate: FORM_FIELDS.LAST_EXPERIENCE,
    jobTitle: FORM_FIELDS.JOB_TITLE,
    jobType: FORM_FIELDS.JOB_TYPE,
    monthlyIncome: FORM_FIELDS.MONTHLY_SALARY,
    unemploymentReason: FORM_FIELDS.UNEMPLOYMENT_REASON,
    seniority: FORM_FIELDS.SENIORITY,
    estateType: FORM_FIELDS.PROPERTY_TYPE,
    carType: FORM_FIELDS.CAR,
    dependentsCount: FORM_FIELDS.NUMBER_OF_DEPENDENTS,
    flatRentAmount: FORM_FIELDS.RENT_APARTMENT,
    additionalIncome: FORM_FIELDS.ADDITIONAL_INCOME,
};

export const FB_FIELDS_EXTRA: Record<string, keyof FB.Request> = {
    HOME_ADDRESS: 'home_address',
    REGISTRATION_ADDRESS: 'registration_address',
};

export enum FB_SMS_STATUS {
    CHECKING_CODE = 1,
    WAITING_CODE,
    CORRECT_CODE,
    WRONG_CODE,
}

export const TABS_KEYS = {
    ONLINE: 'online',
    SUITABLE: 'suitable',
    REFERRAL: 'referral',
} as const;

export const GROUPS_KEYS = {
    ONLINE: 'online',
    FINAL: 'final',
    SUITABLE: 'suitable',
    SUITABLE_MFO: 'suitableMfo',
    NEAREST_CITIES_MFO: 'nearestCitiesMfo',
    UNSUITABLE: 'unsuitable',
    RECOMMENDED: 'recommended',
    RECOMMENDED_MFO: 'recommendedMfo',
    REFERRAL: 'referral',
    NEAREST_CITIES: 'nearestCities',
    SUPER_RELEVANT: 'superRelevant',
    HAS_EXACT_CONDITIONS: 'hasExactConditions',
    ACTIVE: 'active',
    PROMO_BANNER: 'promoBanner',
    FEW_BANKS_BANNER: 'fewBanksBanner',
    EXACT_CONDITIONS: 'exactConditions',
} as const;

/** @deprecated */
export const TABS = {
    [TABS_KEYS.ONLINE]: 'Точные условия',
    [TABS_KEYS.SUITABLE]: 'Другие кредиты',
    [TABS_KEYS.REFERRAL]: 'Реферальные',
} as const;

/** @deprecated */
export const TABS_SHORT = {
    [TABS_KEYS.ONLINE]: 'Точные условия',
    [TABS_KEYS.SUITABLE]: 'Другие кредиты',
    [TABS_KEYS.REFERRAL]: 'Реферальные',
} as const;

export type TabsType = (typeof TABS_KEYS)[keyof typeof TABS_KEYS];
export type GroupType = (typeof GROUPS_KEYS)[keyof typeof GROUPS_KEYS];

export type GroupTreeType = {
    [key in TabsType]: Array<GroupType>;
};

/** @deprecated use GROUPS_KEYS instead */
export const GROUP_TREE: GroupTreeType = {
    [TABS_KEYS.ONLINE]: [GROUPS_KEYS.ONLINE],
    [TABS_KEYS.SUITABLE]: [
        GROUPS_KEYS.PROMO_BANNER,
        GROUPS_KEYS.EXACT_CONDITIONS,
        GROUPS_KEYS.FEW_BANKS_BANNER,
        GROUPS_KEYS.ACTIVE,
        GROUPS_KEYS.SUPER_RELEVANT,
        GROUPS_KEYS.SUITABLE,
        GROUPS_KEYS.SUITABLE_MFO,
        GROUPS_KEYS.NEAREST_CITIES_MFO,
        GROUPS_KEYS.RECOMMENDED,
        GROUPS_KEYS.RECOMMENDED_MFO,
        GROUPS_KEYS.NEAREST_CITIES,
    ],
    [TABS_KEYS.REFERRAL]: [GROUPS_KEYS.REFERRAL],
};

export type GroupsTexts = {
    [key in GroupType]: {
        title: string;
        text: string;
        errors?: {
            title: string;
            text: string;
        };
    };
};

export const GROUPS_TEXTS: Partial<GroupsTexts> = {
    [GROUPS_KEYS.ONLINE]: {
        title: 'Кредит онлайн',
        text: 'В этих банках можно узнать реальные условия и получить кредит без визита в отделение',
    },
    [GROUPS_KEYS.FINAL]: {
        title: 'Предложения с финальной ставкой',
        text: 'Условия не изменятся, если с вашими документами всё в порядке',
    },
    [GROUPS_KEYS.SUITABLE]: {
        title: 'Ваши персональные предложения',
        text: 'Узнайте решение онлайн на Сравни и получите кредит в отделении банка',
    },
    [GROUPS_KEYS.REFERRAL]: {
        title: 'Реферальные предложения',
        text: 'Для оформления нужно перейти на сайт партнёра',
    },
    [GROUPS_KEYS.NEAREST_CITIES]: {
        title: 'Предложения в городах рядом с вами',
        text: 'В вашем городе больше нет предложений, но мы нашли их в городах поблизости',
    },
    [GROUPS_KEYS.RECOMMENDED_MFO]: {
        title: 'Возможно, вам подойдёт',
        text: 'Мы подобрали для вас кредиты на сумму и срок, максимально близкие к вашему запросу',
    },
    [GROUPS_KEYS.RECOMMENDED]: {
        title: 'Возможно, вам подойдёт',
        text: '',
    },
    [GROUPS_KEYS.NEAREST_CITIES_MFO]: {
        title: 'Деньги сразу на карту',
        text: 'Мы подобрали для вас онлайн займы на карту любого банка',
    },
    [GROUPS_KEYS.ACTIVE]: {
        title: 'Мои заявки',
        text: '',
    },
};

export const GROUPS_TEXT_WITH_MFO: Partial<GroupsTexts> = {
    [GROUPS_KEYS.RECOMMENDED_MFO]: {
        title: 'Возможно, вам подойдет',
        text: 'Мы подобрали для вас предложения на сумму и срок, максимально близкие к вашему запросу',
    },
};

export const CURRENCIES: Record<CURRENCY, { SYMBOL: string; SYMBOL_ALTERNATIVE: string }> = {
    RUB: {
        SYMBOL: 'Р',
        SYMBOL_ALTERNATIVE: '₽',
    },
    USD: {
        SYMBOL: '$',
        SYMBOL_ALTERNATIVE: '$',
    },
    EUR: {
        SYMBOL: '',
        SYMBOL_ALTERNATIVE: '',
    },
};

export const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

export const MONTHS_GENITIVE = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
];

/** @deprecated */
export const ADDRESS_FIAS_LEVEL = ['4', '5', '6', '7', '65'];

/**
 * @url https://support.dadata.ru/knowledge-bases/4/articles/1059-urovni-fias-i-urovni-adresa-dadatyi
 * 8 - дом, 65 - планировочная структура, 75 - участок, хозяйство
 */
export const ADDRESS_STREET_HOUSE_FIAS_LEVEL = ['8', '65', '75'];

export const HTTP_ERROR_IMAGES: { [index: number]: string } = {
    404: 'https://www.sravni.ru/f/images/404.png',
    500: 'https://www.sravni.ru/f/images/500.png',
};

export const ASPX_ANONYMOUS_KEY = '.ASPXANONYMOUS';

const AUTO_NUMBER_LETTERS = 'авекмнорстух';

export const MASKS = {
    PHONE: [
        '+',
        '7',
        ' ',
        '(',
        /[1-9]|\*/,
        /\d|\*/,
        /\d|\*/,
        ')',
        ' ',
        /\d|\*/,
        /\d|\*/,
        /\d|\*/,
        '-',
        /\d|\*/,
        /\d|\*/,
        '-',
        /\d|\*/,
        /\d|\*/,
    ],
    DATE: [/\d/, /\d/, '.', /\d/, /\d/, '.', /[1-2]/, /\d/, /\d/, /\d/],
    MONTH: [/\d/, /\d/, '.', /[1-2]/, /\d/, /\d/, /\d/],
    PASSPORT: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    UNIT_CODE: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    FOUR_DIGITS: [/\d/, /\d/, /\d/, /\d/],
    SNILS: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
    AUTO_NUMBER: [
        new RegExp(`[${AUTO_NUMBER_LETTERS}]`, 'i'),
        ' ',
        /\d/,
        /\d/,
        /\d/,
        ' ',
        new RegExp(`[${AUTO_NUMBER_LETTERS}]`, 'i'),
        new RegExp(`[${AUTO_NUMBER_LETTERS}]`, 'i'),
        ' ',
        /\d/,
        /\d/,
        /\d?/,
    ],
};

export const PLACEHOLDERS = {
    PHONE: '+7 (___) ___-__-__',
    DATE: '__.__.____',
    MONTH: '__.____',
    PASSPORT: '____ ______',
    UNIT_CODE: '___-___',
    SNILS: '___-___-___-__',
    AUTO_NUMBER: '_ ___ __ ___',
};

export const AUTO_NUMBER_REG_EXP = new RegExp(`[${AUTO_NUMBER_LETTERS}]\\d{3}[${AUTO_NUMBER_LETTERS}]{2}\\d{2,3}`, 'i');

export const FORM_STEPS = {
    PARAMS: 'params',
    PASSPORT: 'passport',
    WORK: 'work',
    ADDITIONAL: 'additional',
    PERSONAL_DATA: 'personalData',
};

export const EN_RU_BINDING: { [key: string]: string } = {
    q: 'й',
    w: 'ц',
    e: 'у',
    r: 'к',
    t: 'е',
    y: 'н',
    u: 'г',
    i: 'ш',
    o: 'щ',
    p: 'з',
    a: 'ф',
    s: 'ы',
    d: 'в',
    f: 'а',
    g: 'п',
    h: 'р',
    j: 'о',
    k: 'л',
    l: 'д',
    z: 'я',
    x: 'ч',
    c: 'с',
    v: 'м',
    b: 'и',
    n: 'т',
    m: 'ь',
    '[': 'х',
    '{': 'Х',
    ']': 'ъ',
    '}': 'Ъ',
    '`': 'ё',
    '~': 'Ё',
    ';': 'ж',
    ':': 'Ж',
    // '\'': 'э',
    // ',': 'б',
    '<': 'Б',
    // '.': 'ю',
    '>': 'Ю',
    // '|': '/',
    // '"': 'Э',
    // '/': '.',
    // '?': ',',
    // '@': '"',
    // '#': '№',
    // $: ';',
    // '^': ':',
    // '&': '?'
};

export const RU_EN_BINDING = Object.entries(EN_RU_BINDING).reduce<typeof EN_RU_BINDING>((acc, [key, value]) => {
    acc[value] = key;
    return acc;
}, {});

export const PARTNER_COOKIE_KEY = 'prc_creditSelection';

export const AFF_ID_COOKIE_KEY = 'aff_id';

export const INT_ADS_COOKIE_KEY = 'int_ads';

export const INT_CROSS_KEY = 'int_cross';
export const COOKIE_DEFAULT_VALUE = '(not set)';
export const UTMZ_COOKIE_KEY = '__utmz';

export const LOCATION_COOKIE_KEY = '_SL_';
export const IP_LOCATION_COOKIE_KEY = '_ipl';

export const UTM_MARKS = {
    SOURCE: 'utm_source',
    CAMPAIGN: 'utm_campaign',
    MEDIUM: 'utm_medium',
    TERM: 'utm_term',
    CONTENT: 'utm_content',
} as const;

export const INT_ADS_FB_MAP = {
    int_source: 'PromotionSource',
    int_medium: 'PromotionCategory',
    int_campaign: 'PromotionCampaign',
    int_content: 'PromotionSub1',
    int_term: 'PromotionSub2',
} as const;

export const INT_MEDIUM_VALUES = {
    ADS: 'ads',
    CROSS: 'cross',
};

export const UTM_FB_MAP = {
    utm_source: 'UtmSource',
    utm_medium: 'UtmMedium',
    utm_campaign: 'UtmCampaign',
    utm_term: 'UtmTerm',
    utm_content: 'UtmContent',
} as const;

export const UTM_COOKIE_MAP = {
    [UTM_MARKS.SOURCE]: 'utmcsr',
    [UTM_MARKS.CAMPAIGN]: 'utmccn',
    [UTM_MARKS.MEDIUM]: 'utmcmd',
    [UTM_MARKS.TERM]: 'utmctr',
    [UTM_MARKS.CONTENT]: 'utmcct',
} as const;

export const UTM_VALUES = {
    MEDIUM: ['email', 'sms'],
    SOURCE: 'trigger',
    CAMPAIGN: 'creditselection_megafon',
    CONTENT: '--segment_osago',
};

export const COOKIE_PARTNER_KEYS = [
    'aff_id',
    'p_offer_id',
    'source',
    'aff_sub',
    'aff_sub2',
    'aff_sub3',
    'aff_sub4',
    'aff_sub5',
    'tid',
];

export const COOKIE_PARTNER_MAP_TO_FB = {
    p_offer_id: 'aff_offer_id',
    source: 'aff_source',
    tid: 'aff_tid',
};

export const DISABLE_BANK_OFFERS_TIME_IN_DAYS: {
    DEFAULT: number;
    [key: string]: number;
} = {
    DEFAULT: 30,
    [BANKS_ID.SOVCOMBANK_ID]: 60,
} as const;

// Список банков, что принимают предзаявку по кредиту
export const ORGANIZATIONS_WITH_PRELIMINARYREQUEST: Array<string> = [
    // TODO: LOAN-1558 LOAN-1334
    // HOME_CREDIT_BANK_ID
];

export const IGNORE_SCORING_BY_TYPE = 'ignore_scoring_by_type';
export const IGNORE_SCORING_BY_IDS = 'ignore_scoring_by_ids';

export const REVIEWS_PAGE_SIZE = 10;
export const MIN_REVIEWS_COUNT = 3;
export const ORGANIZATION_REVIEWS_MIN_COUNT = 4;

export const ESIA_KEYS = {
    FIRST_NAME: 'esia:firstname',
    MIDDLE_NAME: 'esia:middlename',
    LAST_NAME: 'esia:lastname',
    TRUSTED: 'esia:trusted',
} as const;

export const ESIA_TRUSTED_VALUES = {
    TRUE: 'True',
    FALSE: 'False',
};

export const SUBMIT_REQUEST_EVENT = 'SUBMIT_REQUEST_EVENT';

export const MARKETING_CAMPAIGNS = {
    LOAN_AS_GIFT: {
        beginDate: new Date('2021-10-15'),
        endDate: new Date('2021-11-15'),
        resultDate: new Date('2021-11-30'),
        endDateLoanAccepted: new Date('2021-11-29'),
    },
    REPAY_LOAN: {
        sendRequest: {
            fromDate: new Date('2021-11-25'),
            toDate: new Date('2021-12-25'),
        },
        getLoan: {
            fromDate: new Date('2021-11-25'),
            toDate: new Date('2021-12-31'),
        },
    },
};

export enum ClientAvailabilityStatusType {
    NEED_SIGN_CONSENT_DOCUMENTS = 'NEED_SIGN_CONSENT_DOCUMENTS',
    SUCCESS = 'SUCCESS',
    UNKNOWN = 'UNKNOWN',
}

export const IFRAME_RESIZER_SCROLL_EVENT = 'IFRAME_SCROLLED';

export const IFRAME_RESIZER_MESSAGES = {
    STOP_AUTO_RESIZING: 'STOP_AUTO_RESIZING',
    UPDATE_STATIC_HEIGHT: 'UPDATE_STATIC_HEIGHT',
};

export const ORGANIZATION_CHOICE_TYPE = {
    SUGGEST: 'suggest',
    BY_YOURSELF: 'byYourself',
    AUTO: 'auto',
} as const;

export type OrganizationChoiceType = ValuesOf<typeof ORGANIZATION_CHOICE_TYPE>;

export type ValidationValues = {
    amountMin: number;
    amountMax: number;
};

export const ORGANIZATION_CHOICE_TYPE_EVENT_LABELS: Record<OrganizationChoiceType, string> = {
    [ORGANIZATION_CHOICE_TYPE.SUGGEST]: 'Саджест',
    [ORGANIZATION_CHOICE_TYPE.BY_YOURSELF]: 'Заполнил самостоятельно',
    [ORGANIZATION_CHOICE_TYPE.AUTO]: 'Проброс',
};

export const MPL_CLIENT_SCREEN_STATE_VALUES = {
    INITIAL_CLIENT_LOADING: 'initial_client_loading',
    INITIAL_REGISTER_LOADING: 'initial_register_loading',
    NEW_CLIENT: 'new_client',
    BLOCKED: 'blocked',
    REPEAT_CLIENT: 'repeat_client',
    ACTIVE_DEAL: 'active_deal',
    RE_SIGN: 're_sign',
    SLIDERS: 'sliders',
    REFUSED: 'refused',
    SUCCESSFULLY_CLOSED: 'successfully_closed',
    NOT_ACTIVE: 'not_active',
    DENIED_ESIA: 'denied_esia',
    LOGIN_REQUIRED: 'login_required',
    MPL_DISABLED: 'mpl_disabled',
    PENDING: 'pending',
    EXPIRED: 'expired',
} as const;

export type MplClientScreenStateType = ValuesOf<typeof MPL_CLIENT_SCREEN_STATE_VALUES>;

export const LOCAL_STORAGE_KEY = 'paramsStep';
export const LOCAL_FORM = 'localForm';

export const LOCAL_STORAGE_WL_KEY = 'wlFormData';

export const SESSION_STORAGE_FORM_KEY = 'sessionFormData';

export const LOCAL_STORAGE_DEV_AB_EXPERIMENTS = 'localDevExperiments';

export const CONSENT_TO_PROCESSING_PERSONAL_DATA_URL =
    'https://www.sravni.ru/promo/soglasie-na-obrabotku-personalnyh-dannyh/';
export const CONSENT_TO_ADVERTISING = 'https://f.sravni.ru/cms/file/FilePath/soglasie-na-poluchenie-reklamy.pdf';

export const SESSION_STORAGE_OSAGO_POPUP_KEY = 'isShowedOsagoPopup';
