import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';
import { CategoryFilter, OfferItems } from '../../components/offers.new/types';
import type { UserPredictions } from '../../services/offers/offers.types';
import { CREDIT_SECURITY_VALUES } from '../../constants/sources';
import { ArrangeItem } from '../../helpers/client/arrange';
import { SelectionDictionaries } from '../../services/offers/dictionaries';

interface IState {
    isSuccessResponse: boolean;
    initialized: boolean;
    calcId: string;
    userPredictions: UserPredictions;
    items: OfferItems;
    filters: {
        categoryFilters: Array<CategoryFilter>;
        creditSecurityFilterValue: ValuesOf<typeof CREDIT_SECURITY_VALUES>;
    };
    /** CF-1222 */
    isTinkoffBannerCanceledManually: boolean;
    /** CF-1560 */
    arrange: Record<string, ArrangeItem>;
    /** CF-1786 */
    groups: Array<SelectionDictionaries.TGroup>;
}

const defaultState: IState = {
    isSuccessResponse: false,
    initialized: false,
    calcId: '',
    userPredictions: [],
    items: {
        suitable: [],
        recommended: [],
        final: [],
        unsuitable: [],
        decisionOffers: [],
    },
    filters: {
        categoryFilters: [],
        creditSecurityFilterValue: CREDIT_SECURITY_VALUES.DOES_NOT_MATTER,
    },
    isTinkoffBannerCanceledManually: false,
    arrange: {},
    groups: [],
};

export const setOffersData = createAction<Partial<IState>>('set offers data');

export const setGroups = createAction<IState['groups']>('set offers groups');

/** Позволяет обновить одну группу. Обновляется или добавляется в groups */
export const updateGroup = createAction<SelectionDictionaries.TGroup>('update group');

export const setFilters = createAction<Partial<IState['filters']>>('set filters');

export const setDecisionOffers = createAction<IState['items']['decisionOffers']>('set decisionOffers');

/** Данные, связанные с компонентом выдачи кредита */
export const offerReducer = createReducer({}, defaultState)
    .on(setFilters, (state, payload) => {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...payload,
            },
        };
    })
    .on(setDecisionOffers, (state, payload) => {
        return {
            ...state,
            items: {
                ...state.items,
                decisionOffers: payload,
            },
        };
    })
    .on(setOffersData, (state, payload) => {
        return {
            ...state,
            ...payload,
        };
    })
    .on(setGroups, (state, payload) => {
        return {
            ...state,
            groups: payload,
        };
    })
    .on(updateGroup, (state, payload) => {
        const groupIndex = state.groups.findIndex(({ type }) => type === payload.type);
        if (groupIndex !== -1) {
            state.groups[groupIndex] = payload;
        } else {
            state.groups.push(payload);
        }
        return { ...state, groups: [...state.groups] };
    });

export const useOffers = () => useSelector((state: { offers: IState }) => state.offers);

export const useDecisionOffers = () => useSelector((state: { offers: IState }) => state.offers.items.decisionOffers);

/**
 * Возвращает все группы из ответа products
 * !!! Если нужны определенные группы, используй usePickOffersGroups()
 */
export const useOffersGroups = () => useSelector((state: { offers: IState }) => state.offers.groups);
