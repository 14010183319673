export const HEADERS = {
    /** Идентификатор текущего запроса */
    TRACE_ID: 'X-Trace-Id',
    /** Идентификатор сессии */
    SESSION_ID: 'X-Session-Id',
    /** Идентификатор пользователя SravniId */
    OWNER_ID: 'X-Owner-Id',
    /** Страница и контекст, с которой поступил запрос */
    PAGE_CONTEXT: 'X-Page-Context',
    /** урл с которой сделан запрос */
    PAGE_REFERRER: 'X-Page-Referer',
    /** Ip адрес отправителя */
    USER_IP: 'X-User-Fingerprint',
    /** Группы экспериментов пользователя в виде строки */
    AB_GROUPS: 'X-AB-Groups',
} as const;
