import { v4 as uuidv4 } from 'uuid';

export const getOrCreateDeviceId = (): string => {
    const deviceIdKey = 'deviceId';

    try {
        // может блокироваться в некоторых устройствах
        let deviceId = localStorage.getItem(deviceIdKey);

        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem(deviceIdKey, deviceId);
        }
        return deviceId;
    } catch (e) {
        return uuidv4();
    }
};
