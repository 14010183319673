import axios from 'axios';
import { sendSentryClientError } from '../logger';

export class BduiProvider {
    constructor(protected path: string) {}

    nextFlow = async (flow: { flow: string; branchId: number; userId: string }) => {
        try {
            const { data } = await axios.post(`${this.path}/bdui/next`, flow);
            return data;
        } catch (e) {
            sendSentryClientError(e as Error);
            throw e;
        }
    };
}
