import React from 'react';

import { Icon, Layout, Space } from '@sravni/react-design-system';
import { Telegram, WhatsApp } from '@sravni/react-icons';
import { Text } from '@sravni/react-design-system/lib/Typography';
import { SRAVNI_TELEGRAM, SRAVNI_WHATSAPP } from './constants';
import styles from './styles.module.scss';

export const CustomFooter = () => {
    return (
        <Layout className={styles.wrapper}>
            <Space direction="vertical" size={16}>
                <Space justify={'center'} align={'center'} size={12}>
                    <Space direction="vertical" size={12}>
                        <Text size={14}>Ответим на ваши вопросы</Text>

                        <Space justify={'center'} size={8}>
                            <a href={SRAVNI_TELEGRAM}>
                                <Icon className={styles.link}>
                                    <Telegram />
                                </Icon>
                            </a>

                            <a href={SRAVNI_WHATSAPP}>
                                <Icon className={styles.link}>
                                    <WhatsApp />
                                </Icon>
                            </a>
                        </Space>
                    </Space>
                </Space>
            </Space>
        </Layout>
    );
};
