import axios from 'axios';
import type { ClientPostDealsFastInitParams } from '../../services/mobileGateway/mobileGateway.types';

type DealFastInit = ClientPostDealsFastInitParams;

export class MfoProvider {
    constructor(protected path: string) {}

    get prefix() {
        return `${this.path}/mfo`;
    }

    public postDealFastInit = async (params: DealFastInit) => {
        const { data } = await axios.post<void>(`${this.prefix}/deal/fast-init/`, params);
        return data;
    };
}
