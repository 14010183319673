import { Experiments } from '../../store/reducers/abData';
import {
    CREDIT_SELECTION_ABC_EXPERIMENT_IDS,
    defaultExperimentPagesConfig,
    defaultExperimentUtmMediumConfig,
    experimentPagesConfig,
    experimentUtmMediumConfig,
} from '../../constants';

const getAbExperimentVariant = (experiments: Experiments, name: string): string | number | boolean | undefined => {
    return experiments[name as keyof Experiments];
};

type ExperimentValueArray = Array<string | number | boolean>;
export type ExperimentValue = string | number | boolean | ExperimentValueArray;

/** В зависимости от страницы и значения метки utm_medium, возвращает конфигурацию экспериментов (0 для неактивных) */
export const getUtmAndPageExperiments = (experiments: Experiments, page: string, utmMediumValue = '') => {
    return Object.entries(experiments).reduce(
        (acc, [name, value]) => {
            const pagesConfig = {
                ...defaultExperimentPagesConfig,
                ...experimentPagesConfig[name as keyof typeof experimentPagesConfig],
            };

            const utmMediumConfig = {
                ...defaultExperimentUtmMediumConfig,
                ...experimentUtmMediumConfig[name as keyof typeof experimentUtmMediumConfig],
            };

            const isAvailableOnPage =
                (pagesConfig.included || []).includes(page) ||
                (!(pagesConfig.excluded || []).includes(page) && !(pagesConfig.included || []).length);

            const isAvailableByUtmMedium =
                (utmMediumConfig.included || []).includes(utmMediumValue) ||
                (!(utmMediumConfig.excluded || []).includes(utmMediumValue) &&
                    !(utmMediumConfig.included || []).length);

            return Object.assign(acc, {
                [name]: isAvailableOnPage && isAvailableByUtmMedium ? value : '0',
            });
        },
        {} as typeof experiments,
    );
};

/**
 * Определение группы эксперимента
 * @deprecated use useExperimentGroups instead
 */
export const checkAbExperimentVariant = (
    experiments: Experiments = {},
    page: string,
    utmMedium: string,
    name: string,
    value: ExperimentValue,
) => {
    const pageExperiments = getUtmAndPageExperiments(experiments, page, utmMedium);

    const experimentVariant = getAbExperimentVariant(pageExperiments, name);
    const values = ([] as ExperimentValueArray).concat(value);

    /** По умолчанию всегда '0' */
    return values.includes(experimentVariant || '0');
};

/**
 * Определение группы нескольких экспериментов
 * @deprecated use useExperimentGroups instead
 */
export const checkListOfAbExperimentVariant = (
    experiments: Experiments = {},
    page: string,
    utmMedium: string,
    checkList: Array<{ name: string; value: ExperimentValue }>,
) => {
    return checkList.map((item) => checkAbExperimentVariant(experiments, page, utmMedium, item.name, item.value));
};

export const getSelectionAbExperimentAnalytics = (experiments: Experiments): string => {
    const selection_experiments = Object.values(CREDIT_SELECTION_ABC_EXPERIMENT_IDS).reduce((prev, current) => {
        if (typeof experiments[current] !== 'undefined') {
            return {
                ...prev,
                [current]: experiments[current],
            };
        }
        return prev;
    }, {} as Experiments);

    return Object.keys(selection_experiments).reduce((prev, current) => {
        const exp = `${current}.${String(selection_experiments[current as keyof Experiments])}`;
        return prev ? `${prev}|${exp}` : exp;
    }, '');
};

/** Experiments => string */
export const unwrapStatistics = (statistics: string): Experiments => {
    return statistics
        .split('|')
        .filter((v) => v)
        .reduce((acc, current) => {
            const [key, value] = current.split('.');
            return Object.assign(acc, { [key]: value });
        }, {});
};

/** string => Experiments */
export const wrapStatistics = (experiments: Experiments): string => {
    return Object.entries(experiments).reduce((acc, [key, value]) => {
        return `${acc}|${key}.${value}`;
    }, '');
};
