import { SelectionDictionaries } from './dictionaries';

export const GENDER = {
    MALE: 'male',
    FEMALE: 'female',
    UNKNOWN: 'unknown',
} as const;

export const EMPLOYMENT = {
    EMPLOYMENT: 'Employment',
    BUSINESS_OWNER: 'BusinessOwner',
    JUDGE: 'Judge',
    STATE_EMPLOYEE: 'StateEmployee',
    SERVICEMAN: 'Serviceman',
    RETIRED: 'Retired',
    NOT_WORKING_VALUE: 'NotWorking',
} as const;

export const SOLVENCY_PROOF = {
    NDFL2: 'Ndfl2',
    NDFL3: 'Ndfl3',
    BANK_FORM: 'BankForm',
    NONE: 'None',
} as const;

export const EDUCATION = {
    PRIMARY: 'Primary',
    SECOND_SPECIAL: 'SecondSpecial',
    INCOMPLETE_HIGHER: 'IncompleteHigher',
    HIGHER: 'Higher',
    SECOND_HIGHER: 'SecondHigher',
    ACADEMIC_DEGREE: 'AcademicDegree',
} as const;

export const PROPERTY_TYPE = {
    FLAT: 'Flat',
    APARTMENTS: 'Apartments',
    COMMERCIAL_REAL_ESTATE: 'CommercialRealEstate',
    NOTHING: 'Nothing',
} as const;

export const MARITAL_STATUS = {
    SINGLE: 'Single',
    DIVORCED: 'Divorced',
    CIVIL_MARRIAGE: 'CivilMarriage',
    MARRIED: 'Married',
    WIDOW: 'Widow',
} as const;

export const OPF = {
    OOO: 'OOO',
    IP: 'IP',
    AO: 'AO',
    PAO: 'PAO',
    GBU: 'GBU',
    OAO: 'OAO',
    FGKU: 'FGKU',
    MBU: 'MBU',
    F: 'F',
    ZAO: 'ZAO',
    UNKNOWN: 'UNKNOWN',
} as const;

export const CAR_MANUFACTURER = {
    NO: 'No',
    DOMESTIC: 'Domestic',
    FOREIGN: 'Foreign',
} as const;

export const AREA_OF_EMPLOYMENT = {
    MINING_INDUSTRY: 'MiningIndustry',
    STATE_MUNICIPAL_ADMINISTRATION: 'StateMunicipalAdministration',
    EDUCATION: 'Education',
    HEALTH_CARE_SOCIAL_SERVICES: 'HealthCareSocialServices',
    CULTURE_SPORTS_ACTIVITIES: 'CultureSportsActivities',
    DEFENSE_LAW_ENFORCEMENT_AGENCIES: 'DefenseLawEnforcementAgencies',
    MANUFACTORING_INDUSTRY: 'ManufacturingIndustry',
    PROFESSIONAL_SCIENTIFIC_TECHNICAL_ACTIVITIES: 'ProfessionalScientificTechnicalActivities',
    AGRICULTIRE_FISHING_HUNTING_FORESTRY: 'AgricultureFishingHuntingForestry',
    BUILDING: 'Building',
    TRADE_SERVICES_COMMUNICATIONS: 'TradeServicesCommunications',
    TRANSPORT: 'Transport',
    FINANCE_INSURANCE: 'FinanceInsurance',
    ELECTRICITY_GAS_WATER_HEAT_SUPPLY: 'ElectricityGasWaterHeatSupply',
    OTHER: 'Other',
} as const;

export const CREDIT_SECURITY = {
    DOES_NOT_MATTER: 'NoMatter',
    WITHOUT_BAIL: 'WithoutBail',
    SECURED_BY_AVAILABLE_REAL_ESTATE: 'SecuredByAvailableRealEstate',
    SECURED_BY_AVAILABLE_VEHICLE: 'SecuredByAvailableVehicle',
} as const;

export const PURPOSE = {
    MONEY: 'Money',
    REFINANCE_CREDIT: 'RefinanceCredit',
    NEW_CAR: 'NewCar',
    USED_CAR: 'UsedCar',
} as const;

export const CAUSE_TYPE = {
    ADDITIONAL_PHONE_OWNER: 'additionalPhoneOwner',
    AGE: 'age',
    AMOUNT: 'amount',
    AMOUNT_CUSTOM: 'amountCustom',
    CAR_MANUFACTURER: 'carManufacturer',
    MAX_CREDIT_LIMIT: 'maxCreditLimit',
    CUSTOMER_PROPERTY: 'customerProperty',
    EDUCATION: 'education',
    EMPLOYMENT: 'employment',
    INN: 'inn',
    LAST_PLACEWORK_EXPERIENCE: 'lastPlaceworkExperience',
    MARITAL_STATUS: 'maritalStatus',
    OPF: 'opf',
    OKB: 'okb',
    MONTHLY_INCOME: 'monthlyIncome',
    PERIOD_OF_RESIDENCE: 'periodOfResidence',
    SNILS: 'snils',
    SOLVENCY_PROOF: 'solvencyProof',
    SCORING: 'scoring',
    TERM: 'term',
    INITIAL_AMOUNT: 'initialAmount',
    LOCATION_ROUTE: 'locationRoute',
    EXCLUDE: 'exclude',
    PURPOSE: 'purpose',
} as const;

export const CATEGORY = {
    CREDIT: 'Credit',
    CREDIT_CARD: 'CreditCard',
    MICRO_CREDIT: 'MicroCredit',
    MORTGAGE: 'Mortgage',
    AUTO_CREDIT: 'AutoCredit',
} as const;

type CollectionGroupType = {
    [key: string]: SelectionDictionaries.TGroupType;
};

export const COLLECTION_GROUP: CollectionGroupType = {
    ACTIVE: 'Active',
    SUPER_RELEVANT: 'SuperRelevant',
    EXACT_CONDITIONS: 'ExactConditions',
    SUITABLE_ALL: 'SuitableAll',
    UNSUITABLE_OFFERS: 'UnsuitableOffers',
    RANKING_VECTOR: 'RankingVector',
    SUITABLE: 'Suitable',
    SUITABLE_MFO: 'SuitableMfo',
    RECOMMENDED: 'Recommended',
    RECOMMENDED_MFO: 'RecommendedMfo',
    MAX_GRACE_PERIOD: 'MaxGracePeriod',
    ONLINE: 'Online',
    REFERRAL_OFFERS: 'ReferralOffers',
    PLEDGE_OFFERS: 'PledgeOffers',
    UTILIZATION: 'Utilization',
    AUTO_SEND_OFFERS: 'AutoSendOffers',
    DIFFERENT_CONDITIONS: 'DifferentConditions',
    MFO_OFFERS: 'MfoOffers',
    MFO_FULL_DEAL: 'MfoFullDeal',
    UNSUITABLE_ORGANIZATIONS: 'UnsuitableOrganizations',
} as const;
// satisfies Record<string, SelectionDictionaries.TGroupType>

export const ACTIVE_ITEM_TYPE = {
    FB_STATUS: 'fbStatus',
    MFO_STATUS: 'mfoStatus',
} as const;

export const SUMMARY_TYPE = {
    PurchaseCreditRate: 'PurchaseCreditRate',
    WithdrawalCreditRate: 'WithdrawalCreditRate',
    MinimalPaymentPercent: 'MinimalPaymentPercent',
    RateRange: 'RateRange',
    LoanAmount: 'LoanAmount',
    LoanTerm: 'LoanTerm',
    LoanRate: 'LoanRate',
    LoanRepaymentAmount: 'LoanRepaymentAmount',
    LoanRegularPayment: 'LoanRegularPayment',
    Overpayment: 'Overpayment',
    InitialPaymentSize: 'InitialPaymentSize',
} as const;
// satisfies Record<string, SelectionDictionaries.TSummary>
