import { PAGE } from './pages.constants';
import { UTM_MEDIUM_VALUES } from './utm.constants';

export const CREDIT_SELECTION_ABC_EXPERIMENT_IDS = {
    NEW_FOCUSED_FORM: 'b0a303d4-eec4-4e05',
    PROMOTION_GROUP_ID: '5a827bad-4454-4758',
    DETAILS_BUTTON_TEXT_ID: 'a182b343-7e10-4ba4',
    OFFERS_RANKING: '14dd92b7-2ff9-4253',
    PROMO_TINKOFF_BANNER: '6885c7ab-641e-4f57',
    SUITABLE_OFFERS_BANNER: 'd0fe6e72-5865-49d3',
    ADDITIONAL_QUESTIONS: 'dac1607d-dce9-4dcb',
    EXACT_CONDITIONS: '4ce819b4-ac29-4ce6',
    UTILIZATION_BANKS: '7b16860a-babf-496b',
    COLLECTIONS_ENDLESS_TEST: 'fb8da333-9867-4acc',
    MPL_TAB: '29903bec-7bd6-4dc6',
    AUTO_SEND_BANNER: '14482883-88d6-4191',
    MFO_FULL_DEAL: '235c890a-f764-491a',
    TERM_AMOUNT_RECOMMENDATION: 'acd0f69-3805-4288',
    OFFERS_SUITABLE_ALL: 'c617e4bc-0deb-4639',
    MONEYMAN_LK: 'e65fcbd0-4c7c-4df0',
    FAKE_OFFERS: 'f1f606ad-b4e6-4c38',
    UNSUITABLE_ORGANIZATIONS_INFO: '11ff96a2-015d-4418',
} as const;

interface ExperimentConfigItem {
    /** Страницы, на которых эксперимент активен */
    included?: Array<string>;
    /** Страницы, на которых эксперимент НЕ активен */
    excluded?: Array<string>;
}

/** На каких страницах эксперимент должен работать (по умолчанию на всех, КРОМЕ КЦ И РЕСТОР-ЮЗЕР) */
export const experimentPagesConfig: Partial<
    Record<ValuesOf<typeof CREDIT_SELECTION_ABC_EXPERIMENT_IDS>, ExperimentConfigItem>
> = {
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.UTILIZATION_BANKS]: {
        included: [PAGE.LANDING],
    },
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.NEW_FOCUSED_FORM]: {
        included: [PAGE.LANDING, PAGE.RESTORE],
    },
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.MPL_TAB]: {
        included: [PAGE.LANDING],
    },
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.AUTO_SEND_BANNER]: {
        included: [PAGE.LANDING, PAGE.WAYBACK, PAGE.WHITELABEL],
    },
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.MFO_FULL_DEAL]: {
        included: [PAGE.LANDING],
    },
};

export const defaultExperimentPagesConfig: ExperimentConfigItem = {
    excluded: [PAGE.CALLCENTER, PAGE.RESTORE, PAGE.CARDS_RESTORE, PAGE.CALLCENTER_FULL_PATH],
};

interface ExperimentUtmMediumConfigItem {
    /** значения метки utm_medium, при которых эксперимент активен */
    included?: Array<string>;
    /** значения метки utm_medium, на которых эксперимент не активен (заменяет значение по умолчанию) */
    excluded?: Array<string>;
}

/** при каких значениях метки utm_medium должен работать (по умолчанию для всех, КРОМЕ ПЛАТНОГО ТРАФИКА) */
export const experimentUtmMediumConfig: Partial<
    Record<ValuesOf<typeof CREDIT_SELECTION_ABC_EXPERIMENT_IDS>, ExperimentUtmMediumConfigItem>
> = {
    [CREDIT_SELECTION_ABC_EXPERIMENT_IDS.AUTO_SEND_BANNER]: {
        included: [...UTM_MEDIUM_VALUES.PAID_TRAFFIC, ...UTM_MEDIUM_VALUES.ORGANIC_TRAFFIC],
    },
};

export const defaultExperimentUtmMediumConfig: ExperimentUtmMediumConfigItem = {
    excluded: UTM_MEDIUM_VALUES.PAID_TRAFFIC,
};
