import { createAction, createReducer } from 'redux-act';
import { localStorage } from '../../../helpers/storage';
import { LS_BANNER_REFUSED_TS } from './constants';

interface IState {
    items: Record<string, boolean>;
}

const initialState: IState = {
    items: {},
};

export const setProductStatus = createAction<{ id: string; isRefused: boolean }>('set product disabled');
export const newAutoSendProductReducer = createReducer<IState>({}, initialState).on(
    setProductStatus,
    (state, payload) => {
        localStorage.setItem(LS_BANNER_REFUSED_TS, String(new Date().getTime()));

        return {
            ...state,
            items: {
                ...state.items,
                [payload.id]: payload.isRefused,
            },
        };
    },
);
