import axios from 'axios';
import { getAddressString, pick, retry, sourceNameByValue } from '../';
import { FB_FIELDS_MAP, FB_REQUEST_TYPES, FORM_FIELDS } from '../../constants';
import { ADDITIONAL_PHONE_OWNER_SOURCE, ADDITIONAL_PHONE_OWNER_VALUES } from '../../constants/sources';
import {
    ApproveResponse,
    ExactOffersResponse,
    OtkrytieBranchesResponse,
    OtpCheckProps,
    OtpCheckResponse,
    OtpSendProps,
    OtpSendResponse,
    OtpStatusProps,
    OtpStatusResponse,
    RaiffeisenBranchesResponse,
    RequestResponse,
    RosselhozResponse,
} from '../../services/fb/types';
import { formDataToExactConditions } from '../../store/adapters/formStore';
import { sendSentryClientError } from '../logger';

export class FbProvider {
    private bankIds: Set<number> = new Set();
    constructor(protected path: string) {}

    private _hasActiveRequest(bankId: number): boolean {
        if (!bankId) {
            return false;
        }

        if (this.bankIds.has(bankId)) {
            return true;
        }

        this.bankIds.add(bankId);
        return false;
    }

    private _makeFbData(type: FB_REQUEST_TYPES, data: FE.FormData = {}) {
        const fbData: Partial<FB.Request> = {
            request_type: type,
            fio: [
                data[FB_FIELDS_MAP[FORM_FIELDS.LAST_NAME]],
                data[FB_FIELDS_MAP[FORM_FIELDS.FIRST_NAME]],
                data[FB_FIELDS_MAP[FORM_FIELDS.MIDDLE_NAME]],
            ].join(' '),
            ...pick(data, [
                FB_FIELDS_MAP[FORM_FIELDS.FIRST_NAME],
                FB_FIELDS_MAP[FORM_FIELDS.LAST_NAME],
                FB_FIELDS_MAP[FORM_FIELDS.MIDDLE_NAME],
                FB_FIELDS_MAP[FORM_FIELDS.PURPOSE],
                FB_FIELDS_MAP[FORM_FIELDS.AMOUNT],
                FB_FIELDS_MAP[FORM_FIELDS.AUTO_BRAND],
                FB_FIELDS_MAP[FORM_FIELDS.AUTO_BRAND_ID],
                FB_FIELDS_MAP[FORM_FIELDS.AUTO_MODEL],
                FB_FIELDS_MAP[FORM_FIELDS.AUTO_MODEL_ID],
                FB_FIELDS_MAP[FORM_FIELDS.AUTO_YEAR],
                FB_FIELDS_MAP[FORM_FIELDS.PHONE],
                FB_FIELDS_MAP[FORM_FIELDS.EMAIL],
                FB_FIELDS_MAP[FORM_FIELDS.REGION],
                FB_FIELDS_MAP[FORM_FIELDS.REGION_FULL_NAME],
                FB_FIELDS_MAP[FORM_FIELDS.REGION_LOCATION_ROUTE],
                FB_FIELDS_MAP[FORM_FIELDS.AGREEMENT],
                FB_FIELDS_MAP[FORM_FIELDS.PERIOD],
                FB_FIELDS_MAP[FORM_FIELDS.PARTNER_ID],
                FB_FIELDS_MAP[FORM_FIELDS.INITIAL_FEE],
            ]),
        };

        if ([FB_REQUEST_TYPES.CALCULATION, FB_REQUEST_TYPES.REQUEST, FB_REQUEST_TYPES.FINAL].includes(type)) {
            const ADDITIONAL_PHONE_OWNER_VALUE = sourceNameByValue(
                ADDITIONAL_PHONE_OWNER_VALUES.MY_NUMBER,
                ADDITIONAL_PHONE_OWNER_SOURCE,
            );
            const fioString = `${data[FB_FIELDS_MAP[FORM_FIELDS.LAST_NAME]]} ${
                data[FB_FIELDS_MAP[FORM_FIELDS.FIRST_NAME]]
            } ${data[FB_FIELDS_MAP[FORM_FIELDS.MIDDLE_NAME]]}`;

            Object.assign(
                fbData,
                {
                    home_address: getAddressString(
                        data[FB_FIELDS_MAP[FORM_FIELDS.RESIDENCE_STREET]] as string,
                        data[FB_FIELDS_MAP[FORM_FIELDS.RESIDENCE_APARTMENT]] as string,
                    ),
                    registration_address: getAddressString(
                        data[FB_FIELDS_MAP[FORM_FIELDS.REGISTRATION_STREET]] as string,
                        data[FB_FIELDS_MAP[FORM_FIELDS.REGISTRATION_APARTMENT]] as string,
                    ),
                    extra_phone_owner_fio:
                        ADDITIONAL_PHONE_OWNER_VALUE === data[FB_FIELDS_MAP[FORM_FIELDS.ADDITIONAL_PHONE_OWNER]]
                            ? fioString
                            : data[FB_FIELDS_MAP[FORM_FIELDS.ADDITIONAL_PHONE_OWNER_FIO]],
                    [FB_FIELDS_MAP[FORM_FIELDS.SCORE_GEN7_CL]]:
                        data[FB_FIELDS_MAP.scoreGen7clHit] === false ? 0 : data[FB_FIELDS_MAP.scoreGen7cl],
                },
                pick(data, [
                    /* passport step */
                    FB_FIELDS_MAP[FORM_FIELDS.BIRTHDAY],
                    FB_FIELDS_MAP[FORM_FIELDS.BIRTH_PLACE],
                    FB_FIELDS_MAP[FORM_FIELDS.PASSPORT_DATE],
                    FB_FIELDS_MAP[FORM_FIELDS.PASSPORT_NUMBER],
                    FB_FIELDS_MAP[FORM_FIELDS.UNIT_CODE],
                    FB_FIELDS_MAP[FORM_FIELDS.ISSUED],
                    FB_FIELDS_MAP[FORM_FIELDS.REGISTRATION_STREET],
                    FB_FIELDS_MAP[FORM_FIELDS.REGISTRATION_APARTMENT],
                    FB_FIELDS_MAP[FORM_FIELDS.REGISTRATION_DATE],
                    FB_FIELDS_MAP[FORM_FIELDS.REGISTRATION_ROUTE],
                    FB_FIELDS_MAP[FORM_FIELDS.RESIDENCE_STREET],
                    FB_FIELDS_MAP[FORM_FIELDS.RESIDENCE_APARTMENT],
                    FB_FIELDS_MAP[FORM_FIELDS.RESIDENCE_ROUTE],

                    /* additional step */
                    FB_FIELDS_MAP[FORM_FIELDS.ADDITIONAL_PHONE],
                    FB_FIELDS_MAP[FORM_FIELDS.ADDITIONAL_PHONE_OWNER],
                    FB_FIELDS_MAP[FORM_FIELDS.ADDITIONAL_PHONE_OWNER_FIO],
                    FB_FIELDS_MAP[FORM_FIELDS.EDUCATION],
                    FB_FIELDS_MAP[FORM_FIELDS.FAMILY_STATUS],
                    FB_FIELDS_MAP[FORM_FIELDS.NUMBER_OF_DEPENDENTS],
                    FB_FIELDS_MAP[FORM_FIELDS.ADDITIONAL_INCOME],
                    FB_FIELDS_MAP[FORM_FIELDS.CAR],
                    FB_FIELDS_MAP[FORM_FIELDS.PROPERTY_TYPE],
                    FB_FIELDS_MAP[FORM_FIELDS.RENT_APARTMENT],
                    FB_FIELDS_MAP[FORM_FIELDS.SNILS],

                    /* pledge realty step */
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_OWNER_MYSELF],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_OWNER_SINGLE],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_REALTY_ADDRESS_THE_SAME],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_OWNER_RELATIONSHIP],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_OWNER_FAMILY_STATUS],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_OWNER_FIRST_NAME],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_OWNER_LAST_NAME],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_OWNER_MIDDLE_NAME],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_OWNER_PHONE],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_REALTY_STREET],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_REALTY_BUILDING],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_REALTY_FLAT],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_REALTY_HOUSE],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_REALTY_STRUCTURE],

                    /* pledge auto step */
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_AUTO_NUMBER],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_AUTO_BRAND],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_AUTO_BRAND_ID],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_AUTO_MODEL],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_AUTO_MODEL_ID],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_AUTO_YEAR],
                    FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_AUTO_POWER],
                    ...(typeof FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_AUTO_OWNER_MYSELF] !== 'undefined'
                        ? [FB_FIELDS_MAP[FORM_FIELDS.PLEDGE_AUTO_OWNER_MYSELF]]
                        : []),

                    /* work step */
                    FB_FIELDS_MAP[FORM_FIELDS.EMPLOYMENT_TYPE],
                    FB_FIELDS_MAP[FORM_FIELDS.MONTHLY_SALARY],
                    FB_FIELDS_MAP[FORM_FIELDS.WORK_ROUTE],
                    FB_FIELDS_MAP[FORM_FIELDS.SENIORITY],
                    FB_FIELDS_MAP[FORM_FIELDS.ORGANIZATION_NAME],
                    FB_FIELDS_MAP[FORM_FIELDS.WORK_ADDRESS],
                    FB_FIELDS_MAP[FORM_FIELDS.WORK_PHONE],
                    FB_FIELDS_MAP[FORM_FIELDS.LAST_EXPERIENCE],
                    FB_FIELDS_MAP[FORM_FIELDS.LAST_EXPERIENCE_START_MONTH],
                    FB_FIELDS_MAP[FORM_FIELDS.LAST_EXPERIENCE_START_YEAR],
                    FB_FIELDS_MAP[FORM_FIELDS.AREA_OF_EMPLOYMENT],
                    FB_FIELDS_MAP[FORM_FIELDS.JOB_TITLE],
                    FB_FIELDS_MAP[FORM_FIELDS.JOB_TYPE],
                    FB_FIELDS_MAP[FORM_FIELDS.EMPLOYER_INN],
                    FB_FIELDS_MAP[FORM_FIELDS.EMPLOYER_TYPE],
                    FB_FIELDS_MAP[FORM_FIELDS.UNEMPLOYMENT_REASON],
                    FB_FIELDS_MAP[FORM_FIELDS.ANOTHER_UNEMPLOYMENT_REASON],
                    FB_FIELDS_MAP[FORM_FIELDS.INCOME_CONFIRMATION],

                    /** additional questions CF-1309 */
                    FB_FIELDS_MAP[FORM_FIELDS.WHEN_MONEY_NEEDED],
                    FB_FIELDS_MAP[FORM_FIELDS.HAS_RECENT_APPLICATION],
                    FB_FIELDS_MAP[FORM_FIELDS.RECENT_APPLICATION_CAUSE],
                ]),
            );
        }

        return fbData;
    }

    private _decorateDataForRegularRequest(data: Record<string, any>) {
        return {
            ...data,
            formId: 27,
        };
    }

    sendRequest = async (type: FB_REQUEST_TYPES, data: FE.FormData = {}, additional: Record<string, any> = {}) => {
        if (type === FB_REQUEST_TYPES.REQUEST && this._hasActiveRequest(additional.BankId)) {
            throw new Error(`An active request with BankId ${data.BankId} is already in progress.`);
        }

        const fbData = this._decorateDataForRegularRequest(this._makeFbData(type, data));

        try {
            const { data: response } = await axios.post<RequestResponse>(
                `${this.path}/fb/request/`,
                { ...fbData, ...additional },
                { withCredentials: true },
            );

            if (response.isBadRequest) {
                sendSentryClientError(
                    'Cannot do fb request',
                    {
                        meta: JSON.stringify({
                            fbData,
                            additional,
                        }),
                    },
                    { level: 'info' },
                );
            }

            return response;
        } catch (e) {
            sendSentryClientError(e as Error);

            throw e;
        }
    };

    async sendConfirmPreliminaryRequest(guid: string | number) {
        const { data: response } = await axios.put<RequestResponse>(
            `${this.path}/fb/preliminary/confirm/`,
            { guid },
            { withCredentials: true },
        );
        return response;
    }

    loadStatus = async (guid: string | number): Promise<FB.SingleStatus | object> => {
        const { data } = await axios.get<FB.SingleStatus>(`${this.path}/fb/status/?guid=${guid}`, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        return data;
    };

    loadStatuses = async (userId?: string): Promise<Array<FB.Status>> => {
        try {
            let url = `${this.path}/fb/statuses/`;
            if (userId) {
                url += `?userId=${userId}`;
            }
            const { data } = await retry(() =>
                axios.get<Array<FB.Status>>(url, {
                    headers: { 'X-Requested-With': 'XMLHttpRequest' },
                }),
            );
            return data;
        } catch (e) {
            return [];
        }
    };

    deleteDelayedRequests = async (userId: string, passUserId = false): Promise<number | null> => {
        try {
            let url = `${this.path}/fb/delete-delayed-requests/`;
            if (passUserId) url += `?userId=${userId}`;
            const { data } = await axios.get<number | null>(url, {
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
            });
            return data;
        } catch (e) {
            return null;
        }
    };

    firstTouch = async (id: string, deviceId: string) => {
        await axios.post(`${this.path}/fb/first-touch/`, { id, deviceId });

        return true;
    };

    pickApproved = async (id: string | number) => {
        const { data } = await axios.get<ApproveResponse>(`${this.path}/fb/approve/?id=${id}`, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        return data;
    };

    otpStatus = async (props: OtpStatusProps): Promise<OtpStatusResponse> => {
        const { data } = await axios.post<OtpStatusResponse>(`${this.path}/fb/otp-status/`, props, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        return data;
    };

    otpSend = async (props: OtpSendProps): Promise<OtpSendResponse> => {
        const { data } = await axios.post<OtpSendResponse>(`${this.path}/fb/otp-send/`, props, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        return data;
    };

    otpCheck = async (props: OtpCheckProps): Promise<OtpCheckResponse> => {
        const { data } = await axios.post<OtpCheckResponse>(`${this.path}/fb/otp-check/`, props, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
        });
        return data;
    };

    getOtkrytieBranches = async () => {
        const { data } = await axios.get<OtkrytieBranchesResponse>(`${this.path}/fb/branches/otkrytie/`);
        return data;
    };

    getRaiffeisenBranches = async () => {
        const { data } = await axios.get<RaiffeisenBranchesResponse>(`${this.path}/fb/branches/raiffeisen/`);
        return data;
    };

    getRosselhozBranches = async () => {
        const { data } = await axios.get<RosselhozResponse>(`${this.path}/fb/branches/rosselhoz/`);
        return data;
    };

    getUserRequests = async (): Promise<{
        items: Array<FB.RequestWithExactConditions>;
        total: number;
    }> => {
        const { data } = await axios.get(`${this.path}/fb/user/requests/`);
        return data;
    };

    searchExactOffers = async (
        data: FE.FormData = {},
        products: Array<{ bankId: string; productId: string }>,
        payload?: {
            calcId?: string;
            sourceUrl?: string;
        },
        userId?: string,
    ): Promise<string | null> => {
        const fbData = formDataToExactConditions(data);
        let url = `${this.path}/fb/exact-offers/search/`;
        if (userId) url += `?userId=${userId}`;

        try {
            const {
                data: { searchId = '' },
            } = await retry(() =>
                axios.post<{ searchId?: string | null }>(
                    url,
                    { ...fbData, ...payload, products },
                    { withCredentials: true },
                ),
            );
            return searchId;
        } catch (e) {
            return null;
        }
    };

    getExactOffers = async (userId?: string): Promise<ExactOffersResponse | null> => {
        let url = `${this.path}/fb/exact-offers/`;
        if (userId) url += `?userId=${userId}`;

        try {
            const { data } = await axios.get<ExactOffersResponse>(url);

            return data;
        } catch (e) {
            return null;
        }
    };
}
