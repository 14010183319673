import React from 'react';
import { Footer as FooterDefault } from '@sravni/react-footer';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../store';
import { PAGE } from '../../../constants';

import { CustomFooter } from '../../CustomFooter';
import styles from './footer.module.scss';

interface IFooterProps {
    page?: string;
}

export const Footer: React.FC<IFooterProps> = ({ page }) => {
    const settings = useSelector((state: IGlobalState) => state.siteSettings.footer);

    const Component = page === PAGE.AVITO ? CustomFooter : FooterDefault;

    return (
        <div className={styles['footer-container']}>
            <Component menuLinks={settings.menu} socialLinks={settings.socialLinks} support={settings.support} />
        </div>
    );
};
