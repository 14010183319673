import axios from 'axios';
import { IMLData } from '../../store/adapters/formStore';
import { IRecommendedTermAndAmount } from '../../components/offers.new/types';
import { sendSentryClientError } from '../logger';

export class TermAmountProvider {
    url: string;

    constructor(protected path: string) {
        this.url = `${path}/term-amount`;
    }
    getAmountAndTerm = async (params: IMLData) => {
        try {
            const { data } = await axios.post<IRecommendedTermAndAmount>(`${this.url}/amount-and-term/`, params);
            return data;
        } catch (e) {
            sendSentryClientError(e as Error, { ErrorBoundaryId: 'ApiService' });
        }
    };
}
