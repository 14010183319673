import { InternalAxiosRequestConfig } from 'axios';
import { HEADERS, UTM_MARKS } from '../../../constants';
import { getStore } from '../../../store/client';
import { getUtmAndPageExperiments, wrapStatistics } from '../../experiments';
import { marksSelector } from '../../../store/reducers/utmMarks';
import { ClientTraceContext } from '../../clientTraceContext';

/** Browser only */
export const clientAxiosInterceptorOnFulfilled = (config: InternalAxiosRequestConfig) => {
    const state = getStore()?.getState();

    if (!state) {
        return config;
    }

    const { sessionId, ownerId, pageContext } = ClientTraceContext.getTraceData();
    const traceId = ClientTraceContext.getTraceId();

    const { page } = state.formStore || {};
    const { experiments } = state.abData || {};
    const marks = state.utmMarks || {};
    const { [UTM_MARKS.MEDIUM]: utmMedium } = marksSelector(marks);

    config.headers[HEADERS.SESSION_ID] = sessionId;
    config.headers[HEADERS.TRACE_ID] = traceId;
    config.headers[HEADERS.PAGE_CONTEXT] = pageContext;
    config.headers[HEADERS.OWNER_ID] = ownerId || undefined;
    config.headers[HEADERS.AB_GROUPS] =
        wrapStatistics(getUtmAndPageExperiments(experiments, page, utmMedium)) || undefined;
    return config;
};
