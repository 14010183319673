import { createAction, createReducer } from 'redux-act';
import isEqual from 'lodash/isEqual';
import { extendFormDataMiddleware } from '../adapters/formStore';
import {
    AMOUNT_LIMITS,
    FORM_FIELDS,
    ORGANIZATION_CHOICE_TYPE,
    OrganizationChoiceType,
    PAGE_CONTEXT,
    ValidationValues,
} from '../../constants';
import { CREDIT_SECURITY_VALUES, NUMBER_OF_DEPENDENTS_SOURCE, PURPOSE_SOURCE_VALUES } from '../../constants/sources';
import { omit, pick } from '../../helpers';
import { SourceItem } from '../../components/landing.vitrina.product/components/form.params.step/helpers';
import { WPViews } from '../../components/form/steps/work/constants';
import { PEOPLE_NAMES } from '../../services/profile.graphql/constants';
import { ClientTraceContext } from '../../helpers/clientTraceContext';

export type IState = FE.FormStore & {
    profileMetaData: {
        selectedPeopleUpdated: string;
        selectedPeople: ValuesOf<typeof PEOPLE_NAMES>;
    } | null;
    /** Контекст страницы, активный раздел */
    pageContext: ValuesOf<typeof PAGE_CONTEXT>;
    formData: FE.FormData;
    organizationChoiceType: OrganizationChoiceType;
    validations: ValidationValues;
    stepsFieldsOptions: {
        paramsStep: {
            autoModels: Array<SourceItem>;
            autoModelYears: Array<SourceItem>;
            modelsIsLoading: boolean;
            yearsIsLoading: boolean;
        };
        passportStep: {
            issuedBy: Array<string>;
            issuedByIsLoading: boolean;
        };
        workStep: {
            workPlaceView: WPViews;
        };
    };
};

const defaultFormValues: FE.FormData = {
    [FORM_FIELDS.PHONE]: '',
    [FORM_FIELDS.SMS_CODE]: '',
    [FORM_FIELDS.AGREEMENT]: false,
    [FORM_FIELDS.PURPOSE]: PURPOSE_SOURCE_VALUES.MONEY,
    [FORM_FIELDS.PERIOD]: 'P1Y',
    [FORM_FIELDS.CREDIT_SECURITY]: CREDIT_SECURITY_VALUES.DOES_NOT_MATTER,
    [FORM_FIELDS.INITIAL_FEE]: 0,
    [FORM_FIELDS.NUMBER_OF_DEPENDENTS]: NUMBER_OF_DEPENDENTS_SOURCE[0].value,
    [FORM_FIELDS.REGISTRATION_DADATA_FULL]: {},
    [FORM_FIELDS.RESIDENCE_DADATA_FULL]: {},
    [FORM_FIELDS.PRODUCT_TYPES]: '',
    [FORM_FIELDS.FORCE_UPDATE]: '', // Для принудительного обновления данных - any string like uuid4()
};

const initialState: IState = {
    autoRun: false,
    profileMetaData: null,
    fbPayload: {},
    formData: defaultFormValues,
    page: '',
    pageContext: PAGE_CONTEXT.DEFAULT,
    organizationChoiceType: ORGANIZATION_CHOICE_TYPE.AUTO,
    oncePassedSteps: [],
    disabledFields: [],
    validations: {
        amountMin: AMOUNT_LIMITS.MIN,
        amountMax: AMOUNT_LIMITS.MAX,
    },
    stepsFieldsOptions: {
        paramsStep: {
            autoModels: [],
            autoModelYears: [],
            modelsIsLoading: false,
            yearsIsLoading: false,
        },
        passportStep: {
            issuedBy: [],
            issuedByIsLoading: false,
        },
        workStep: {
            workPlaceView: WPViews.wpHidden,
        },
    },
};

/** Переписывает указанные ВСПОМОГАТЕЛЬНЫЕ данные формы */
export const extendFormStore = createAction<Partial<IState>>('extend form store');
/** Расширяет дополнительные параметры, отправляющиеся в FB заявку (напрямую) */
export const extendFbPayload = createAction<Partial<IState['fbPayload']>>('extend formbuilder payload');
export const clearFbPayloadFields = createAction<Array<keyof FE.FbPayload>>('clear formbuilder payload fields');
export const extendValidations = createAction<Partial<IState['validations']>>('extend validations payload');
/** Переписывает указанные данные формы анкеты */
export const extendFormData = createAction<FE.FormData>('extend form data');
/** Полностью заменяет данные формы */
export const clearFormData = createAction<FE.FormData>('clear form data');
/** Переписывает данные справочников полей шага параметров  */
export const updateParamsStepOptions = createAction<Partial<IState['stepsFieldsOptions']['paramsStep']>>(
    'update params step options data',
);
/** Переписывает данные справочников полей шага паспорт  */
export const updatePassportStepOptions = createAction<Partial<IState['stepsFieldsOptions']['passportStep']>>(
    'update passport step options data',
);
/** Переписывает данные шага работы  */
export const updateWorkStepOptions =
    createAction<Partial<IState['stepsFieldsOptions']['workStep']>>('update work step data');
export const setPageContext = createAction<ValuesOf<typeof PAGE_CONTEXT>>('set page context');

export const formStoreReducer = createReducer({}, initialState)
    .on(extendFormStore, (state, payload) => {
        return isEqual(payload, pick(state, Object.keys(payload) as any)) ? state : { ...state, ...payload };
    })
    .on(extendFbPayload, (state, payload) => ({ ...state, fbPayload: { ...state.fbPayload, ...payload } }))
    .on(clearFbPayloadFields, (state, excludedFields) => ({
        ...state,
        fbPayload: omit(state.fbPayload || {}, excludedFields),
    }))
    .on(extendValidations, (state, payload) => ({
        ...state,
        validations: { ...state.validations, ...payload },
    }))
    .on(clearFormData, (state, payload) => ({
        ...state,
        formData: extendFormDataMiddleware(defaultFormValues, payload),
    }))
    .on(extendFormData, (state, payload) => {
        return isEqual(payload, pick(state.formData, Object.keys(payload) as any))
            ? state
            : {
                  ...state,
                  formData: extendFormDataMiddleware(state.formData, payload),
              };
    })
    .on(updateParamsStepOptions, (state, payload) => ({
        ...state,
        stepsFieldsOptions: {
            ...state.stepsFieldsOptions,
            paramsStep: { ...state.stepsFieldsOptions.paramsStep, ...payload },
        },
    }))
    .on(updatePassportStepOptions, (state, payload) => ({
        ...state,
        stepsFieldsOptions: {
            ...state.stepsFieldsOptions,
            passportStep: { ...state.stepsFieldsOptions.passportStep, ...payload },
        },
    }))
    .on(setPageContext, (state, pageContext) => {
        ClientTraceContext.setPageContext([state.page, pageContext].filter(Boolean).join('.'));
        return { ...state, pageContext };
    })
    .on(updateWorkStepOptions, (state, payload) => ({
        ...state,
        stepsFieldsOptions: {
            ...state.stepsFieldsOptions,
            workStep: { ...state.stepsFieldsOptions.workStep, ...payload },
        },
    }));
