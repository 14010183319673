import { capitalize } from '../helpers';
import {
    AREA_OF_EMPLOYMENT,
    CAR_MANUFACTURER,
    CREDIT_SECURITY,
    EDUCATION,
    EMPLOYMENT,
    MARITAL_STATUS,
    PROPERTY_TYPE,
    PURPOSE,
    SOLVENCY_PROOF,
} from '../services/offers/offers.constants';
import { MONTHS } from './index';

export const CAR_VALUES = {
    NO: CAR_MANUFACTURER.NO,
    DOMESTIC: CAR_MANUFACTURER.DOMESTIC,
    FOREIGN: CAR_MANUFACTURER.FOREIGN,
};

export const UNEMPLOYMENT_REASONS = {
    RETIRED_JUDGE: 'RetiredJudge',
    /** @deprecated use EMPLOYMENT_VALUES.RETIRED */
    PENSIONER_BY_AGE: 'PensionerByAge',
    /** @deprecated use EMPLOYMENT_VALUES.RETIRED */
    PENSIONER_BY_DISABILITY: 'PensionerByDisability',
    SEARCH_JOB: 'SearchJob',
    SPOUSE: 'Spouse',
    OTHER: 'Other',
};

export const PURPOSE_SOURCE_VALUES = PURPOSE;

const OLD_PURPOSE_TO_NEW = {
    Repairs: PURPOSE_SOURCE_VALUES.MONEY,
    NewCar: PURPOSE_SOURCE_VALUES.NEW_CAR,
    UsedCar: PURPOSE_SOURCE_VALUES.USED_CAR,
    RepaymentOfCredit: PURPOSE_SOURCE_VALUES.REFINANCE_CREDIT,
    Education: PURPOSE_SOURCE_VALUES.MONEY,
    BusinessDevelopment: PURPOSE_SOURCE_VALUES.MONEY,
    Treatment: PURPOSE_SOURCE_VALUES.MONEY,
    RepaymentOfDebts: PURPOSE_SOURCE_VALUES.MONEY,
    Shopping: PURPOSE_SOURCE_VALUES.MONEY,
    Journey: PURPOSE_SOURCE_VALUES.MONEY,
    Celebration: PURPOSE_SOURCE_VALUES.MONEY,
    HelpToRelatives: PURPOSE_SOURCE_VALUES.MONEY,
    Other: PURPOSE_SOURCE_VALUES.MONEY,
    NotDecide: PURPOSE_SOURCE_VALUES.MONEY,
    RefinanceMortgage: PURPOSE_SOURCE_VALUES.MONEY,
};

export const oldPurposeToNew = (value: string) => {
    if (!value) {
        return;
    }

    const entry = Object.entries(OLD_PURPOSE_TO_NEW).find(([key]) => key.toLowerCase() === value.toLowerCase());

    return entry
        ? entry[1]
        : Object.values(PURPOSE_SOURCE_VALUES).find((item) => item.toLowerCase() === value.toLowerCase()) || value;
};

export const CREDIT_SECURITY_VALUES = CREDIT_SECURITY;

export const CREDIT_SECURITY_SOURCE = [
    { value: CREDIT_SECURITY_VALUES.DOES_NOT_MATTER, name: 'Не важно' },
    { value: CREDIT_SECURITY_VALUES.WITHOUT_BAIL, name: 'Без залога' },
    { value: CREDIT_SECURITY_VALUES.SECURED_BY_AVAILABLE_REAL_ESTATE, name: 'Под залог недвижимости' },
    { value: CREDIT_SECURITY_VALUES.SECURED_BY_AVAILABLE_VEHICLE, name: 'Под залог автомобиля' },
];

export const EMPLOYMENT_VALUES = EMPLOYMENT;

export const ADDITIONAL_PHONE_OWNER_VALUES = {
    MY_NUMBER: 'MyNumber',
    RELATIVE_NUMBER: 'RelativeNumber',
    FRIEND_NUMBER: 'FriendNumber',
};

export const NUMBER_OF_DEPENDENTS_VALUES = {
    NONE: 'None',
    ONE: '1',
    TWO: '2',
    THREE: '3',
    MORE_THAN_THREE: 'MoreThanThree',
};

export const NUMBER_OF_DEPENDENTS_SOURCE = [
    { name: 'Нет', value: NUMBER_OF_DEPENDENTS_VALUES.NONE },
    { name: '1', value: NUMBER_OF_DEPENDENTS_VALUES.ONE },
    { name: '2', value: NUMBER_OF_DEPENDENTS_VALUES.TWO },
    { name: '3', value: NUMBER_OF_DEPENDENTS_VALUES.THREE },
    { name: 'Больше 3', value: NUMBER_OF_DEPENDENTS_VALUES.MORE_THAN_THREE },
];

export const INCOME_CONFIRMATION_SOURCE = [
    { name: 'Справка 2-НДФЛ', value: SOLVENCY_PROOF.NDFL2 },
    { name: 'Справка 3-НДФЛ', value: SOLVENCY_PROOF.NDFL3 },
    { name: 'Справка по форме банка', value: SOLVENCY_PROOF.BANK_FORM },
    { name: 'Без подтверждения дохода', value: SOLVENCY_PROOF.NONE },
];

export const SENIORITY_SOURCE = [
    { name: 'До 1 года', value: 'P0Y' },
    { name: 'От 1 года до 3 лет', value: 'P1Y' },
    { name: 'От 3 до 10 лет', value: 'P3Y' },
    { name: 'Более 10 лет', value: 'P10Y' },
];

export const SENIORITY_PERIODS = [
    { value: 'P0Y', period: { from: null, to: 1 } },
    { value: 'P1Y', period: { from: 1, to: 3 } },
    { value: 'P3Y', period: { from: 3, to: 10 } },
    { value: 'P10Y', period: { from: 10, to: null } },
];

export const JOB_TYPE_SOURCE = [
    {
        name: 'Руководитель организации',
        value: 'headOfOrganization',
    },
    {
        name: 'Руководитель подразделения',
        value: 'headOfDepartment',
    },
    {
        name: 'Неруководящий сотрудник - специалист',
        value: 'specialist',
    },
    {
        name: 'Неруководящий сотрудник - обсл. персонал',
        value: 'serviceStaff',
    },
];

export const OLD_PURPOSE_SOURCE = [
    { name: 'Ремонт', value: 'Repairs', default: true },
    { name: 'Покупка нового автомобиля', value: 'NewCar' },
    { name: 'Покупка подержанного автомобиля', value: 'UsedCar' },
    { name: 'Погашение кредитов', value: 'RepaymentOfCredit' },
    { name: 'Учеба', value: 'Education' },
    { name: 'Развитие бизнеса', value: 'BusinessDevelopment' },
    { name: 'Лечение', value: 'Treatment' },
    { name: 'Погашение долгов (не кредиты)', value: 'RepaymentOfDebts' },
    { name: 'Шоппинг', value: 'Shopping' },
    { name: 'Путешествие', value: 'Journey' },
    { name: 'Торжество', value: 'Celebration' },
    { name: 'Помощь близким', value: 'HelpToRelatives' },
    { name: 'Иное', value: 'Other' },
    { name: 'Пока не определился', value: 'NotDecide' },
];

export const PURPOSE_SOURCE: Array<{ name: string; value: ValuesOf<typeof PURPOSE_SOURCE_VALUES>; default?: boolean }> =
    [
        { name: 'Просто деньги', value: PURPOSE_SOURCE_VALUES.MONEY, default: true },
        { name: 'Рефинансирование кредита', value: PURPOSE_SOURCE_VALUES.REFINANCE_CREDIT },
        { name: 'Покупка нового автомобиля', value: PURPOSE_SOURCE_VALUES.NEW_CAR },
        { name: 'Покупка подержанного автомобиля', value: PURPOSE_SOURCE_VALUES.USED_CAR },
    ];

export const AREA_OF_EMPLOYMENT_SOURCE = [
    { name: 'Горнодобывающая промышленность', value: AREA_OF_EMPLOYMENT.MINING_INDUSTRY, okved: '05-09' },
    {
        name: 'Государственное, муниципальное управление',
        value: AREA_OF_EMPLOYMENT.STATE_MUNICIPAL_ADMINISTRATION,
        okved: '84-84.21,84.25-84.4',
    },
    { name: 'Образование', value: AREA_OF_EMPLOYMENT.EDUCATION, okved: '85' },
    {
        name: 'Здравоохранение, социальные услуги',
        value: AREA_OF_EMPLOYMENT.HEALTH_CARE_SOCIAL_SERVICES,
        okved: '86-88',
    },
    {
        name: 'Культура, искусство, спортивная деятельность',
        value: AREA_OF_EMPLOYMENT.CULTURE_SPORTS_ACTIVITIES,
        okved: '90-93',
    },
    {
        name: 'Оборона, правоохранительные органы',
        value: AREA_OF_EMPLOYMENT.DEFENSE_LAW_ENFORCEMENT_AGENCIES,
        okved: '84.23-84.24',
    },
    {
        name: 'Обрабатывающая промышленность (производство)',
        value: AREA_OF_EMPLOYMENT.MANUFACTORING_INDUSTRY,
        okved: '10-34',
    },
    {
        name: 'Профессиональная, научная, техническая деятельность',
        value: AREA_OF_EMPLOYMENT.PROFESSIONAL_SCIENTIFIC_TECHNICAL_ACTIVITIES,
        okved: '69-75',
    },
    {
        name: 'Сельское хозяйство, рыболовство, охота, лесоводство',
        value: AREA_OF_EMPLOYMENT.AGRICULTIRE_FISHING_HUNTING_FORESTRY,
        okved: '01-04',
    },
    { name: 'Строительство', value: AREA_OF_EMPLOYMENT.BUILDING, okved: '41-43' },
    {
        name: 'Сфера торговли, услуг, связи',
        value: AREA_OF_EMPLOYMENT.TRADE_SERVICES_COMMUNICATIONS,
        okved: '45-47,55-63,77-82,95-98',
    },
    { name: 'Транспорт', value: AREA_OF_EMPLOYMENT.TRANSPORT, okved: '49-53' },
    { name: 'Финансовая деятельность, страхование', value: AREA_OF_EMPLOYMENT.FINANCE_INSURANCE, okved: '64-68' },
    {
        name: 'Электроэнергия, газо-, водо-, теплоснабжение',
        value: AREA_OF_EMPLOYMENT.ELECTRICITY_GAS_WATER_HEAT_SUPPLY,
        okved: '35-39',
    },
    { name: 'Иное (в крайнем случае)', value: AREA_OF_EMPLOYMENT.OTHER },
];

export const EMPLOYER_TYPE_SOURCES: Array<{ name: string; value: string; wayback: string; code: string }> = [
    { name: 'ООО', value: 'OOO', wayback: 'OOO', code: '12300' },
    { name: 'ИП', value: 'IP', wayback: 'IP', code: '50102' },
    { name: 'АО', value: 'AO', wayback: 'AO', code: '12200' },
    { name: 'ПАО', value: 'PAO', wayback: 'PAO', code: '12247' },
    { name: 'НАО', value: 'NAO', wayback: 'NAO', code: '12267' },
    {
        name: 'Государственное бюджетное учреждение',
        value: 'StateInstitution',
        wayback: 'StateInstitution',
        code: '75203',
    },
    {
        name: 'Федеральное государственное казенное учреждение',
        value: 'FederalStateTreasury',
        wayback: 'FederalStateTreasury',
        code: '75104',
    },
    {
        name: 'Муниципальное бюджетное учреждение',
        value: 'MunicipalInstitution',
        wayback: 'MunicipalInstitution',
        code: '75403',
    },
    { name: 'Филиал', value: 'Branch', wayback: 'Branch', code: '30002' },
    { name: 'Я не знаю', value: 'DontKnow', wayback: 'DontKnow', code: '0' },
    // { name: 'ОАО', wayback: 'OAO' },
    // { name: 'ЗАО', wayback: 'ZAO' },
];

export const EMPLOYMENT_TYPE_SOURCE = [
    {
        name: 'Работа по найму',
        value: EMPLOYMENT_VALUES.EMPLOYMENT,
        default: true,
        notEmployment: false,
    },
    {
        name: 'Собственный бизнес',
        value: EMPLOYMENT_VALUES.BUSINESS_OWNER,
        notEmployment: true,
    },
    {
        name: 'Судья',
        value: EMPLOYMENT_VALUES.JUDGE,
        notEmployment: false,
    },
    {
        name: 'Военнослужащий',
        value: EMPLOYMENT_VALUES.SERVICEMAN,
        notEmployment: false,
    },
    {
        name: 'Госслужащий',
        value: EMPLOYMENT_VALUES.STATE_EMPLOYEE,
        notEmployment: false,
    },
    {
        name: 'Пенсионер',
        value: EMPLOYMENT_VALUES.RETIRED,
        notEmployment: false,
    },
    {
        name: 'Не работаю',
        value: EMPLOYMENT_VALUES.NOT_WORKING_VALUE,
    },
];

export const LAST_EXPERIENCE_MONTHS_SOURCE = new Array(12).fill(0).map((item, index) => ({
    value: index + 1 > 9 ? (index + 1).toString() : `0${index + 1}`,
    name: capitalize(MONTHS[index]),
}));

export const LAST_EXPERIENCE_YEARS_SOURCE = new Array(new Date().getFullYear() - 1970).fill(0).map((i, k) => ({
    value: (new Date().getFullYear() - k).toString(),
    name: (new Date().getFullYear() - k).toString(),
}));

export const UNEMPLOYMENT_REASON_SOURCE = [
    { name: 'Судья в отставке', value: UNEMPLOYMENT_REASONS.RETIRED_JUDGE, hasIncome: true },
    { name: 'Ищу работу', value: UNEMPLOYMENT_REASONS.SEARCH_JOB, hasIncome: false },
    { name: 'Содержит муж/жена', value: UNEMPLOYMENT_REASONS.SPOUSE, hasIncome: false },
    { name: 'Другое', value: UNEMPLOYMENT_REASONS.OTHER, hasIncome: false },
];

export const ADDITIONAL_PHONE_OWNER_SOURCE = [
    { name: 'Мой номер', value: ADDITIONAL_PHONE_OWNER_VALUES.MY_NUMBER },
    { name: 'Номер родственника', value: ADDITIONAL_PHONE_OWNER_VALUES.RELATIVE_NUMBER },
    { name: 'Номер друга', value: ADDITIONAL_PHONE_OWNER_VALUES.FRIEND_NUMBER },
];

export const CAR_SOURCE = [
    { name: 'Нет', value: CAR_VALUES.NO },
    { name: 'Отечественный', value: CAR_VALUES.DOMESTIC },
    { name: 'Иномарка', value: CAR_VALUES.FOREIGN },
];

export const EDUCATION_SOURCE = [
    { name: 'Начальное, среднее', value: EDUCATION.PRIMARY },
    { name: 'Среднее специальное', value: EDUCATION.SECOND_SPECIAL },
    { name: 'Неполное высшее', value: EDUCATION.INCOMPLETE_HIGHER },
    { name: 'Высшее', value: EDUCATION.HIGHER },
    { name: 'Второе высшее', value: EDUCATION.SECOND_HIGHER },
    { name: 'Ученая степень', value: EDUCATION.ACADEMIC_DEGREE },
];

export const FAMILY_STATUS_SOURCE = [
    { name: 'Холост/не замужем', value: MARITAL_STATUS.SINGLE },
    { name: 'Разведен(а)', value: MARITAL_STATUS.DIVORCED },
    { name: 'Гражданский брак', value: MARITAL_STATUS.CIVIL_MARRIAGE },
    { name: 'Женат/замужем', value: MARITAL_STATUS.MARRIED },
    { name: 'Вдовец/вдова', value: MARITAL_STATUS.WIDOW },
];

export const PLEDGE_OWNER_RELATIONSHIP_SOURCE = [
    { name: 'Супруг/супруга', value: 'Spouse' },
    { name: 'Родитель', value: 'Parent' },
    { name: 'Ребёнок', value: 'Child' },
    { name: 'Другой родственник', value: 'AnotherRelative' },
    { name: 'Другое', value: 'Other' },
];

export const PROPERTY_TYPE_SOURCE = [
    { name: 'Квартира', value: PROPERTY_TYPE.FLAT },
    { name: 'Апартаменты', value: PROPERTY_TYPE.APARTMENTS },
    { name: 'Коммерческая недвижимость', value: PROPERTY_TYPE.COMMERCIAL_REAL_ESTATE },
    { name: 'Нет', value: PROPERTY_TYPE.NOTHING },
];

export const PERIOD_SOURCE = [
    { name: '7 дней', value: 'P7D', months: 0.23, years: 0.019, default: false },
    { name: '14 дней', value: 'P14D', months: 0.46, years: 0.038, default: false },
    { name: '21 день', value: 'P21D', months: 0.7, years: 0.057, default: false },
    { name: '1 месяц', value: 'P1M', months: 1, years: 0.083, default: false },
    { name: '6 месяцев', value: 'P6M', months: 6, years: 0.5, default: false },
    { name: '1 год', value: 'P1Y', months: 12, years: 1, default: false },
    { name: '2 года', value: 'P2Y', months: 24, years: 2, default: false },
    { name: '3 года', value: 'P3Y', months: 36, years: 3, default: false },
    { name: '4 года', value: 'P4Y', months: 48, years: 4, default: false },
    { name: '5 лет', value: 'P5Y', months: 60, years: 5, default: true },
    { name: '7 лет', value: 'P7Y', months: 84, years: 7, default: false },
    { name: '10 лет', value: 'P10Y', months: 120, years: 10, default: false },
    { name: '15 лет', value: 'P15Y', months: 180, years: 15, default: false },
];

export const PRODUCT_CATEGORIES: Record<string, string> = {
    CreditCard: 'Кредитная карта',
    Credit: 'Кредит',
    MicroCredit: 'Микрозаймы',
    AutoCredit: 'Автокредит',
    Mortgage: 'Ипотека',
};

export const MPL_CALL_CENTER_WORKING_HOURS_TEXT = 'Ежедневно с 9:00 до 20:00 по МСК';
