import { createAction, createReducer } from 'redux-act';

import type { FilterResponse, IExtendedOffersResponse } from '../../../components/marketplace.offers/types';

interface IState {
    initials?: FilterResponse;
    offers?: IExtendedOffersResponse;
}

const defaultState: IState = {};

export const setMatricesInitials = createAction<FilterResponse>('marketplace/offers/setMatricesInitials');
export const setMatricesOffers = createAction<IExtendedOffersResponse>('marketplace/offers/setMatricesOffers');

export const marketplaceOffersReducer = createReducer((on) => {
    on(setMatricesInitials, (state, payload) => ({
        ...state,
        initials: payload,
    }));
    on(setMatricesOffers, (state, payload) => ({
        ...state,
        offers: payload,
    }));
}, defaultState);
