import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

type Props = {
    errorText?: React.ReactNode;
    additionalText?: React.ReactNode;
    errorTextPosition?: 'left' | 'right';
} & React.HTMLProps<HTMLDivElement>;

export const ErrorTextWrapper: React.FC<Props> = ({
    errorText,
    className,
    errorTextPosition = 'left',
    additionalText,
    children,
    ...other
}) => {
    return (
        <div {...other} className={styles.error_wrapper}>
            {children}
            {errorText ? (
                <div
                    className={cn(
                        styles.error,
                        { [styles['error_align-right']]: errorTextPosition === 'right' },
                        className,
                    )}>
                    {errorText}
                </div>
            ) : additionalText ? (
                <div className={styles.message}>{additionalText}</div>
            ) : null}
        </div>
    );
};
