/** oldId банков */
export const enum BANKS_ID {
    KHLYNOV = '164',
    LOKO = '661',
    AKBARS = '1068',
    CITIBANK_ID = '266',
    MTS_ID = '278',
    OTKRITIYE_FK_ID = '361',
    ROSSSELHOZ_BANK_ID = '254',
    HOME_CREDIT_BANK_ID = '673',
    SOVCOMBANK_ID = '170',
    RAIFFEISEN_BANK_ID = '330',
    ALFA_BANK_ID = '326',
    MIGCREDIT_ID = '143002',
    ZENIT_BANK_ID = '285',
    TINKOFF_BANK_ID = '645',
    ATB_ID = '57',
    PROMSVIAZBANK_ID = '553',
    SKB_BANK_ID = '902',
    SBER_ID = '276',
    URALSIB_BANK_ID = '338',
    VTB_ID = '333',
    GAZPROMBANK_ID = '340',
    POCHTA_BANK_ID = '72',
    OTKRITIE_BANK_ID = '942',
    OTP_BANK_ID = '291',
    METALLINVEST_BANK_ID = '257',
    NORVIK_BANK_ID = '165',
    RENESSANS_BANK_ID = '777',
    CREDIT_EUROPA_BANK_ID = '337',
    VOSTOCHNIY_EXPRESS = '53',
    EXPOBANK = '719',
    MKB = '743',
    ROSBANK = '283',
    /** @deprecated bank not active */
    FINSPIN_ID = '5e62272bf535d1001cd9e1dc',
}

export const BANKS_NEW_ID = {
    VTB_ID: '5bb4f767245bc22a520a6081',
    ALFA_BANK_ID: '5bb4f767245bc22a520a60fb',
};

export const PRODUCTS_ID = {
    AUTO2CREDIT: '5f3bc40cae791b001fe8960e',
    CREDIT_DOCTOR: '618d12cbeb9a29001f454bad',
    PROMO_SKB: '6024f11999f157001f1b7312',
    PROMO_RAIFFAIZEN: '5e5782ac1861f6001122e17b',
    PROMO_WEBBANKIR: '5e95c9aa31b105001bb766b8',
    PROMO_ZAIMER: '5e95e240b92fe7001be7598c',
    RAIFFEISEN_REFINANCE: '5e5782ea1861f6001122fbdb',
    RAIFFEISEN_CASH_CREDIT: '5e5782ac1861f6001122e17b',
    TINKOFF_CREDIT_CARD: 'fa89115a-b3f2-4286-a9c3-6c7d45d1aeba--72edfbe3-9fef-435f-a62a-8fe25c00441e',
    TINKOFF_PLATINUM_CREDIT_CARD: 'ec395ef7-4911-4da6-bc47-140b443d4d19--72edfbe3-9fef-435f-a62a-8fe25c00441e',
    CITI_BANK_CREDIT_CARD_SELECT: 'df810eed-86e9-428e-912b-8e377659d1a2--72edfbe3-9fef-435f-a62a-8fe25c00441e',
    ALFA_CARD_GOD_BEZ_PROCENTOV: 'f5c7865d-5a0e-4679-a965-bf90debe6764--bae435f2-eaa7-4e60-b121-85e4e18197c8',
    MTS_CARD_CASHBACK: '498625df-dd09-4c53-8fa8-ca07cc2f4299',
    MTS_CARD_CASHBACK_DECISION: '2ffb4c02-b7c9-4fe1-8905-731444d9f7d9',
    MTS_CARD_WEEKEND: 'd84f9f39-c5db-41ef-acc5-7c7f64812e6b',
    MTS_CARD_WEEKEND_DECISION: '23969ec7-b9df-4d5d-b881-bf967dbfaf51',
    MTS_CARD_ZERO_DECISION: 'b0b0a8cf-a6a6-4830-9af6-f052985dbc76',
    MTS_REFINANCE: '6490102934ab300027a28fee',
    MTS_REFINANCE_DECISION: '6655d4c516a629002abf4f30',
    MTS_NA_LYUBYE_CELI: '6490102934ab300027a28fee',
    MTS_NA_LYUBYE_CELI_DECISION: '62d023ab1ef40d0023a43a80',
    MTS_NALICHNIMI: '5ea154fc0973a2001b13692a',
    OTKRITIYE_120_DAYS: '933b18e8-a75b-437e-bd73-9d3643fa2d6f',
    ATB_CASH_CREDIT: '61387808f1b8fb001f0e7599',
    MOBILE_WEBBANKIR: '62f241a980299b0023b64dcb',
    MKB_PROFITABLE_SEASON: '63c7bd80fb2e830026f92f5c',
    SKB_COMPLETELY_ONLINE: '622f4a001e7f230020a23d00',
    URALSIB_CARD_120_DAYS_MAXIMUM: '5f65c606-413b-4d0e-9424-02c15b30535c--133df7f2-b676-40d3-8aaa-f575dcf6b1f6',
    TINKOFF_CARD_PLATINUM_PROMO_PROD: 'fa89115a-b3f2-4286-a9c3-6c7d45d1aeba--133df7f2-b676-40d3-8aaa-f575dcf6b1f6',
    TINKOFF_CARD_PLATINUM_PROD_1: 'fa89115a-b3f2-4286-a9c3-6c7d45d1aeba',
    TINKOFF_CARD_PLATINUM_PROD_2: '73d0914d-de21-4b99-89d2-2a4a3c2dbc3b',
    TINKOFF_CARD_PLATINUM_PROD_OLD: '169',
    TINKOFF_CARD_PLATINUM_PROMO_DEV: '7414fab1-1e7f-42e3-ad6f-c2ba4e994f44',
    TINKOFF_CARD_PLATINUM_PROMO_DEV_2: '73882e08-0dc2-499c-b7d4-f5c5f1a6c7aa',
    TINKOFF_CARD_PLATINUM_PROMO_DEV_OLD: '169',
    TINKOFF_NALICHNIMI: '5e57828f1861f6001122d4b5',
    TINKOFF_NALICHNIMI_WITH_AUTO_PLEDGE: '29958',
    MTS_CARD_CASHBACK_PROMO: '498625df-dd09-4c53-8fa8-ca07cc2f4299--133df7f2-b676-40d3-8aaa-f575dcf6b1f6',
    SVOY_BANK_SVOYA_KREDITKA: 'ef69d26e-639f-4100-9ecc-2e1338084c5f--133df7f2-b676-40d3-8aaa-f575dcf6b1f6',
    SVOY_BANK_SVOYA_KREDITKA_2: '8f2e1329-9036-4513-8863-b3f37b2e3844--133df7f2-b676-40d3-8aaa-f575dcf6b1f6',
    ATB_UNIVERSAL_CARD: '7d0ae9ba-7f56-42fe-ae02-82955d658ef3--a1caa9db-7510-45f9-b554-3d2611ab5c50',
    ROSSELHOZBANK_NA_LUBIE_CELI: '64f6e21d1da838002762d50c',
    MTS_CREDIT_CARMA: '06aef925-4f5d-459c-8720-e3ff15bae82a--133df7f2-b676-40d3-8aaa-f575dcf6b1f6',
    MTS_CC_CARMA: '3ca4f4b6-3da8-47a9-a184-e653291b3092--133df7f2-b676-40d3-8aaa-f575dcf6b1f6',
};

export const PAYMENT_SYSTEM_IDS = {
    MIR_ID: '133df7f2-b676-40d3-8aaa-f575dcf6b1f6',
};

export const MTS_DECISION_PRODUCTS = {
    CARDS: [
        PRODUCTS_ID.MTS_CARD_CASHBACK_DECISION,
        PRODUCTS_ID.MTS_CARD_WEEKEND_DECISION,
        PRODUCTS_ID.MTS_CARD_ZERO_DECISION,
    ],
    CREDITS: [PRODUCTS_ID.MTS_NA_LYUBYE_CELI_DECISION, PRODUCTS_ID.MTS_REFINANCE_DECISION],
};
