import flatten from 'lodash/flatten';
import { parse, format } from 'date-fns';
import { ADDRESS_POSTAL_CODE_REGEXP, FB_FIELDS_MAP, FORM_FIELDS } from '../../constants';
import {
    durationToMonths,
    filterNumeric,
    formatAutoNumber,
    isProduction,
    omit,
    parseZonedDate,
    removeUndefined,
    sourceNameByValue,
} from '../../helpers';
import {
    ADDITIONAL_PHONE_OWNER_SOURCE,
    AREA_OF_EMPLOYMENT_SOURCE,
    EDUCATION_SOURCE,
    EMPLOYER_TYPE_SOURCES,
    EMPLOYMENT_TYPE_SOURCE,
    FAMILY_STATUS_SOURCE,
    INCOME_CONFIRMATION_SOURCE,
    JOB_TYPE_SOURCE,
    NUMBER_OF_DEPENDENTS_SOURCE,
    PERIOD_SOURCE,
    PLEDGE_OWNER_RELATIONSHIP_SOURCE,
    PURPOSE_SOURCE,
    PURPOSE_SOURCE_VALUES,
    SENIORITY_SOURCE,
    UNEMPLOYMENT_REASON_SOURCE,
    CAR_SOURCE,
    PROPERTY_TYPE_SOURCE,
    EMPLOYMENT_VALUES,
} from '../../constants/sources';
import { ADDITIONAL_PHONE_OWNER_DEPS, EMPLOYMENT_TYPE_DEPS, PURPOSE_DEPS } from '../../constants/field.dependencies';
import { UserDataParams } from '../../helpers/client/arrange';

/** Преобразует формат внутренней формы в FB */
export const formDataToFb = (formData: FE.FormData): Partial<FB.Request> => {
    const converters: Record<string, (value: FE.FieldValue) => FE.FieldValue> = {
        [FORM_FIELDS.AMOUNT]: (v) => String(v),
        [FORM_FIELDS.ADDITIONAL_INCOME]: (v) => String(v),
        [FORM_FIELDS.PERIOD]: (v) => sourceNameByValue(v, PERIOD_SOURCE, 'months'),
        [FORM_FIELDS.REGION_ID]: (v) => filterNumeric(v),
        [FORM_FIELDS.PURPOSE]: (v) => sourceNameByValue(v, PURPOSE_SOURCE),
        [FORM_FIELDS.PHONE]: (phone = '') => phone.toString().replace('+7', '').replace(/\D/g, ''),
        [FORM_FIELDS.PASSPORT_NUMBER]: (v) => (v || '').toString().replace(/\D/g, ''),
        [FORM_FIELDS.ISSUED]: (v) =>
            (v || '')
                .toString()
                .replace(/\r?\n|\r/g, ' ')
                .trim(),
        [FORM_FIELDS.EDUCATION]: (v) => sourceNameByValue(v, EDUCATION_SOURCE),
        [FORM_FIELDS.FAMILY_STATUS]: (v) => sourceNameByValue(v, FAMILY_STATUS_SOURCE),
        [FORM_FIELDS.NUMBER_OF_DEPENDENTS]: (v) => sourceNameByValue(v, NUMBER_OF_DEPENDENTS_SOURCE),
        [FORM_FIELDS.ADDITIONAL_PHONE]: (phone) => (phone || '').toString().replace(/\D/g, ''),
        [FORM_FIELDS.ADDITIONAL_PHONE_OWNER]: (v) => sourceNameByValue(v, ADDITIONAL_PHONE_OWNER_SOURCE),
        [FORM_FIELDS.EMPLOYMENT_TYPE]: (v) => sourceNameByValue(v, EMPLOYMENT_TYPE_SOURCE),
        [FORM_FIELDS.EMPLOYER_TYPE]: (v) => sourceNameByValue(v, EMPLOYER_TYPE_SOURCES),
        [FORM_FIELDS.AREA_OF_EMPLOYMENT]: (v) => sourceNameByValue(v, AREA_OF_EMPLOYMENT_SOURCE),
        [FORM_FIELDS.UNEMPLOYMENT_REASON]: (v) => sourceNameByValue(v, UNEMPLOYMENT_REASON_SOURCE),
        [FORM_FIELDS.JOB_TYPE]: (v) => sourceNameByValue(v, JOB_TYPE_SOURCE),
        [FORM_FIELDS.WORK_PHONE]: (phone) => (phone || '').toString().replace('+7', '').replace(/\D/g, ''),
        [FORM_FIELDS.INCOME_CONFIRMATION]: (v) => sourceNameByValue(v, INCOME_CONFIRMATION_SOURCE),
        [FORM_FIELDS.SENIORITY]: (v) => sourceNameByValue(v, SENIORITY_SOURCE),
        [FORM_FIELDS.PLEDGE_REALTY_ADDRESS_THE_SAME]: (v) => Boolean(v),
        [FORM_FIELDS.PLEDGE_OWNER_SINGLE]: (v) => Boolean(v),
        [FORM_FIELDS.PLEDGE_OWNER_PHONE]: (phone = '') =>
            phone.toString().replace('+7', '').replace(/\D/g, '') || undefined,
        [FORM_FIELDS.PLEDGE_OWNER_FAMILY_STATUS]: (v) =>
            sourceNameByValue(v as string, FAMILY_STATUS_SOURCE) || undefined,
        [FORM_FIELDS.PLEDGE_OWNER_RELATIONSHIP]: (v) =>
            sourceNameByValue(v as string, PLEDGE_OWNER_RELATIONSHIP_SOURCE) || undefined,
        [FORM_FIELDS.PLEDGE_AUTO_NUMBER]: formatAutoNumber,
        [FORM_FIELDS.CAR]: (v) => sourceNameByValue(v as string, CAR_SOURCE) || undefined,
        [FORM_FIELDS.PROPERTY_TYPE]: (v) => sourceNameByValue(v as string, PROPERTY_TYPE_SOURCE) || undefined,
    };

    return Object.entries(formData).reduce<Partial<FB.Request>>((acc, [key, value]) => {
        const converter = converters[key];
        const fbKey = FB_FIELDS_MAP[key];
        if (fbKey) {
            Object.assign(acc, { [fbKey]: converter ? converter(value) : value });
        }
        return acc;
    }, {});
};

/** Преобразует формат внутренней формы в запрос продуктов с точными условиями */
export const formDataToExactConditions = (formData: FE.FormData): FB.RequestExactConditions => {
    const fullPassportNumber = (formData[FORM_FIELDS.PASSPORT_NUMBER] || '').toString().replace(/\D/g, '');
    return {
        firstName: formData[FORM_FIELDS.FIRST_NAME],
        lastName: formData[FORM_FIELDS.LAST_NAME],
        phone: (formData[FORM_FIELDS.PHONE] || '').toString().replace(/\D/g, '').slice(-10),
        birthDate: format(
            parse((formData[FORM_FIELDS.BIRTHDAY] || '').toString(), 'dd.MM.yyyy', new Date()),
            'yyyy-MM-dd',
        ),
        birthPlace: formData[FORM_FIELDS.BIRTH_PLACE],
        passport: {
            issuedDate: format(
                parse((formData[FORM_FIELDS.PASSPORT_DATE] || '').toString(), 'dd.MM.yyyy', new Date()),
                'yyyy-MM-dd',
            ),
            code: (formData[FORM_FIELDS.UNIT_CODE] || '').toString().replace(/\D/g, ''),
            series: fullPassportNumber.slice(0, 4),
            number: fullPassportNumber.slice(-6),
            issuer: formData[FORM_FIELDS.ISSUED],
        },
    };
};

/** Преобразует формат внутренней формы в запрос ранжирования по ОКБ */
export const formDataToArrangeOkb = (formData: FE.FormData): UserDataParams => {
    const passportSeriesNumber = (formData.sn || '').replace(/\D/g, '');
    const passportSerial = passportSeriesNumber.slice(0, 4);
    const passportNumber = passportSeriesNumber.slice(4);
    const isDevelopment = !isProduction;

    return {
        name: formData.firstName,
        surname: formData.lastName,
        patronymic: formData.surname,
        birthday: parseZonedDate((formData.birthDate || '').toString(), 'dd.MM.yyyy').toISOString(),
        passportNumber,
        passportSerial,
        passportIssueDate: parseZonedDate((formData.issueDate || '').toString(), 'dd.MM.yyyy').toISOString(),
        ...(isDevelopment ? { email: formData.email } : {}),
    };
};

/** Преобразует формат внутренней формы в профиль */
export const formDataToProfile = (formData: FE.FormData) => {
    return removeUndefined({
        ...formData,
        amount: typeof formData[FORM_FIELDS.AMOUNT] === 'undefined' ? undefined : String(formData[FORM_FIELDS.AMOUNT]),
        initialFee:
            typeof formData[FORM_FIELDS.INITIAL_FEE] === 'undefined'
                ? undefined
                : String(formData[FORM_FIELDS.INITIAL_FEE]),
        regionId:
            typeof formData[FORM_FIELDS.REGION_ID] === 'undefined'
                ? undefined
                : Number(formData[FORM_FIELDS.REGION_ID]),
    });
};

const workDependencyFields = Array.from(new Set(flatten(Object.values(EMPLOYMENT_TYPE_DEPS))));
const purposeDependencyFields = Array.from(new Set(flatten(Object.values(PURPOSE_DEPS))));
const additionalPhoneOwnerDependencyFields = Array.from(new Set(flatten(Object.values(ADDITIONAL_PHONE_OWNER_DEPS))));

const clearDependentFields = (formData: FE.FormData): FE.FormData => {
    const {
        [FORM_FIELDS.EMPLOYMENT_TYPE]: employmentType,
        [FORM_FIELDS.PURPOSE]: purpose,
        [FORM_FIELDS.ADDITIONAL_PHONE_OWNER]: additionalPhoneOwner,
        [FORM_FIELDS.HAS_RECENT_APPLICATION]: hasRecentApplication,
    } = formData;

    const unnecessaryFields = flatten([
        workDependencyFields.filter(
            (field) =>
                !(EMPLOYMENT_TYPE_DEPS[employmentType as ValuesOf<typeof EMPLOYMENT_VALUES>] || []).includes(field),
        ),
        purposeDependencyFields.filter(
            (field) => !(PURPOSE_DEPS[purpose as keyof typeof PURPOSE_DEPS] || []).includes(field),
        ),
        additionalPhoneOwnerDependencyFields.filter(
            (field) => !(ADDITIONAL_PHONE_OWNER_DEPS[additionalPhoneOwner] || []).includes(field),
        ),
        ...(hasRecentApplication !== 'Да' ? [FORM_FIELDS.RECENT_APPLICATION_CAUSE] : []),
    ]);

    return omit(formData, unnecessaryFields);
};

const manageAddressFields = (formData: FE.FormData) => {
    // Удаляем почтовый индекс из адреса
    if (formData[FORM_FIELDS.REGISTRATION_STREET]) {
        formData[FORM_FIELDS.REGISTRATION_STREET] = String(formData[FORM_FIELDS.REGISTRATION_STREET]).replace(
            ADDRESS_POSTAL_CODE_REGEXP,
            '',
        );
    }
    if (formData[FORM_FIELDS.RESIDENCE_STREET]) {
        formData[FORM_FIELDS.RESIDENCE_STREET] = String(formData[FORM_FIELDS.RESIDENCE_STREET]).replace(
            ADDRESS_POSTAL_CODE_REGEXP,
            '',
        );
    }

    // Если адреса совпадают, то копируем данные из регистрации в проживание
    if (formData[FORM_FIELDS.RESIDENCE_SAME_REGISTRATION]) {
        formData[FORM_FIELDS.RESIDENCE_STREET] = formData[FORM_FIELDS.REGISTRATION_STREET];
        formData[FORM_FIELDS.RESIDENCE_APARTMENT] = formData[FORM_FIELDS.REGISTRATION_APARTMENT];
        formData[FORM_FIELDS.RESIDENCE_ROUTE] = formData[FORM_FIELDS.REGISTRATION_ROUTE];
        formData[FORM_FIELDS.RESIDENCE_DADATA_FULL] = formData[FORM_FIELDS.REGISTRATION_DADATA_FULL];
    }

    // Дополняем данные dadata значениями из отдельных полей формы (улица и квартира)
    if (formData[FORM_FIELDS.REGISTRATION_DADATA_FULL]) {
        const registrationStreet = formData[FORM_FIELDS.REGISTRATION_STREET];
        const registrationApartment = formData[FORM_FIELDS.REGISTRATION_APARTMENT];
        const registrationAddress = [registrationStreet, registrationApartment].filter((v) => v).join(', кв ');

        formData[FORM_FIELDS.REGISTRATION_DADATA_FULL] = {
            ...formData[FORM_FIELDS.REGISTRATION_DADATA_FULL],
            value: registrationStreet,
            data: {
                ...(formData[FORM_FIELDS.REGISTRATION_DADATA_FULL]
                    ? formData[FORM_FIELDS.REGISTRATION_DADATA_FULL].data
                    : {}),
                source: registrationAddress,
                result: registrationAddress,
                ...(registrationApartment
                    ? { flat_type: 'кв', flat_type_full: 'квартира', flat: registrationApartment }
                    : { flat_type_full: null, flat_type: null, flat: null }),
            },
        };
        formData.registrationAddress = registrationAddress;
    }

    if (formData[FORM_FIELDS.RESIDENCE_DADATA_FULL]) {
        const residenceStreet = formData[FORM_FIELDS.RESIDENCE_STREET];
        const residenceApartment = formData[FORM_FIELDS.RESIDENCE_APARTMENT];
        const residenceAddress = [residenceStreet, residenceApartment].filter((v) => v).join(', кв ');

        formData[FORM_FIELDS.RESIDENCE_DADATA_FULL] = {
            ...formData[FORM_FIELDS.RESIDENCE_DADATA_FULL],
            value: residenceStreet,
            data: {
                ...(formData[FORM_FIELDS.RESIDENCE_DADATA_FULL]
                    ? formData[FORM_FIELDS.RESIDENCE_DADATA_FULL].data
                    : {}),
                source: residenceAddress,
                result: residenceAddress,
                ...(residenceApartment
                    ? { flat_type: 'кв', flat_type_full: 'квартира', flat: residenceApartment }
                    : { flat_type_full: null, flat_type: null, flat: null }),
            },
        };
        formData.residenceAddress = residenceAddress;
    }
};

/** Изменяет одни поля, в зависимости от других */
export const extendFormDataMiddleware = (formData: FE.FormData, payload: FE.FormData): FE.FormData => {
    const normalized = Object.entries(payload).reduce((acc, [key, value]) => {
        const trimmedValue = typeof value === 'string' ? value.trim() : value;
        return Object.assign(acc, { [key]: trimmedValue });
    }, {} as FE.FormData);

    const newData = {
        ...formData,
        ...normalized,
    };

    manageAddressFields(newData);

    /** Set initial fee for several purposes */

    const initialFeeByPurpose = [PURPOSE_SOURCE_VALUES.NEW_CAR, PURPOSE_SOURCE_VALUES.USED_CAR].includes(
        normalized[FORM_FIELDS.PURPOSE],
    )
        ? { [FORM_FIELDS.INITIAL_FEE]: normalized[FORM_FIELDS.INITIAL_FEE] || 0 }
        : {};

    /** Set last experience date */

    const lastExperienceChanged =
        FORM_FIELDS.LAST_EXPERIENCE_START_MONTH in payload || FORM_FIELDS.LAST_EXPERIENCE_START_YEAR in payload;
    const lastExperienceStartDate = lastExperienceChanged
        ? newData[FORM_FIELDS.LAST_EXPERIENCE_START_MONTH] && newData[FORM_FIELDS.LAST_EXPERIENCE_START_YEAR]
            ? {
                  [FORM_FIELDS.LAST_EXPERIENCE]: `01.${newData[FORM_FIELDS.LAST_EXPERIENCE_START_MONTH]}.${
                      newData[FORM_FIELDS.LAST_EXPERIENCE_START_YEAR]
                  }`,
              }
            : {}
        : {};

    /** Set Full Name */

    const fullName =
        newData[FORM_FIELDS.FULL_NAME] ||
        [newData[FORM_FIELDS.LAST_NAME], newData[FORM_FIELDS.FIRST_NAME], newData[FORM_FIELDS.MIDDLE_NAME]]
            .filter((v) => v)
            .join(' ');

    const employerInn = /^\d+$/.test(newData[FORM_FIELDS.EMPLOYER_INN]) ? newData[FORM_FIELDS.EMPLOYER_INN] : undefined;

    const employmentType = newData[FORM_FIELDS.EMPLOYMENT_TYPE];
    const incomeConfirmation =
        employmentType === EMPLOYMENT_VALUES.NOT_WORKING_VALUE
            ? ''
            : employmentType === EMPLOYMENT_VALUES.RETIRED
              ? 'None'
              : 'Ndfl2';

    return clearDependentFields(
        Object.assign(newData, {
            [FORM_FIELDS.FULL_NAME]: fullName,
            [FORM_FIELDS.EMPLOYER_INN]: employerInn,
            [FORM_FIELDS.INCOME_CONFIRMATION]: incomeConfirmation,
            ...initialFeeByPurpose,
            ...lastExperienceStartDate,
        }),
    );
};

export interface IMLData {
    userBirthSettlement?: string;
    userGender?: number;
    userEducation?: string;
    userScoreOkbKiwi?: number;
    userScoreGen7?: number;
    userDeviceType?: string;
    userMaritalStatus?: string;
    userDependentsNum?: string;
    userJobEmploymentType?: string;
    userJobOrgName?: string;
    userJobOrgPosition?: string;
    userJobOrgType?: string;
    userJobOrgInn?: string;
    userPropertyType?: string;
    requestFormId?: number;
    utmSource?: string;
    utmMedium?: string;
    creditIssueCityName?: string;
    creditIssueRegionName?: string;
    creditIssueDistrictName?: string;
    creditAmount: number;
    creditTermInMonths: number;
    userAge?: number;
    userJobMonthlySalary: number;
    bankName?: string;
    productType?: string;
    guarantee?: number;
    lowPay?: number;
    variant: number;
}

export const formDataToML = (formData: FE.FormData, variant: number): IMLData => {
    return {
        userBirthSettlement: formData[FORM_FIELDS.BIRTH_PLACE],
        userGender: formData[FORM_FIELDS.GENDER] === 'male' ? 0 : 1,
        userEducation: sourceNameByValue(formData[FORM_FIELDS.EDUCATION], EDUCATION_SOURCE)?.toString(),
        userScoreOkbKiwi: formData[FORM_FIELDS.OKB_SCORE],
        userScoreGen7: formData[FORM_FIELDS.SCORE_GEN7_CC],
        userMaritalStatus: sourceNameByValue(formData[FORM_FIELDS.FAMILY_STATUS], FAMILY_STATUS_SOURCE)?.toString(),
        userDependentsNum: formData[FORM_FIELDS.NUMBER_OF_DEPENDENTS],
        userJobEmploymentType: sourceNameByValue(
            formData[FORM_FIELDS.EMPLOYMENT_TYPE],
            EMPLOYMENT_TYPE_SOURCE,
        )?.toString(),
        userJobOrgName: formData[FORM_FIELDS.ORGANIZATION_NAME],
        userJobOrgPosition: formData[FORM_FIELDS.JOB_TYPE],
        userJobOrgType: formData[FORM_FIELDS.EMPLOYER_TYPE],
        userJobOrgInn: formData[FORM_FIELDS.EMPLOYER_INN],
        userPropertyType: sourceNameByValue(formData[FORM_FIELDS.PROPERTY_TYPE], PROPERTY_TYPE_SOURCE)?.toString(),
        creditIssueCityName: formData[FORM_FIELDS.REGION],
        creditIssueRegionName: formData[FORM_FIELDS.REGION_FULL_NAME],
        creditAmount: Math.round(Number(formData[FORM_FIELDS.AMOUNT]) / 1000) * 1000,
        creditTermInMonths: Math.max(Math.round(durationToMonths(formData[FORM_FIELDS.PERIOD]) / 12) * 12, 12),
        userJobMonthlySalary: Number(formData[FORM_FIELDS.MONTHLY_SALARY]),
        variant,
    };
};
