import { isIframe } from '../../helpers';
import analytics from '../../helpers/analytics';

export const sendAuthAnalytics = (eventCategory: ValuesOf<typeof analytics.categories> = analytics.categories.Loan) => {
    if (isIframe()) {
        analytics.emit({
            event: analytics.events.mainEvent,
            eventCategory,
            eventAction: 'Верификация',
        });
    } else {
        analytics.emit({
            event: analytics.events.mainEvent,
            eventCategory,
            eventAction: 'Вход в лк',
            eventLabel: 'Распознан по номеру',
        });
    }
};
