import { FORM_FIELDS } from './form.constants';

export const UTM_PARAMS = {
    UTM_MEDIUM: 'utm_medium',
    UTM_SOURCE: 'utm_source',
    UTM_CAMPAIGN: 'utm_campaign',
    UTM_CONTENT: 'utm_content',
    UTM_TERM: 'utm_term',
} as const;

export const INT_PARAMS = {
    INT_SOURCE: 'int_source',
    INT_TERM: 'int_term',
    INT_MEDIUM: 'int_medium',
    INT_CAMPAIGN: 'int_campaign',
    INT_CONTENT: 'int_content',
} as const;

const PARTNER_PARAMS = {
    PARTNER_KEY: 'aff_id',
    PARTNER_AFF_OFFER_ID: 'aff_offer_id',
    PARTNER_OFFER_ID: 'offer_id',
    SOURCE: 'source',
    AFF_SOURCE: 'aff_source',
    AFF_SUB: 'aff_sub',
    AFF_SUB2: 'aff_sub2',
    AFF_SUB3: 'aff_sub3',
    AFF_SUB4: 'aff_sub4',
    AFF_SUB5: 'aff_sub5',
    TRANSACTION_ID: 'transaction_id',
    AGENT_ID: 'agentid',
    SRAVNI_AFF_SUB3: 'sravni_aff_sub3',
} as const;

const FORM_PARAMS = {
    AMOUNT: 'amount',
    PURPOSE: 'purpose',
    PERIOD: 'period',
    EMAIL: 'email',
} as const;

const WHITELABEL_PARAMS = {
    WL_AMOUNT: 'desired_credit_amount',
    WL_PERIOD: 'desired_credit_term_years',
    WL_PURPOSE: FORM_PARAMS.PURPOSE,
    WL_PHONE: 'phone_mobile',
    WL_EMAIL: FORM_PARAMS.EMAIL,
    WL_INITIAL_FEE: 'down_payment',
    WL_FIRST_NAME: 'name',
    WL_LAST_NAME: 'surname',
    WL_PATRONYMIC: 'patronymic',
    WL_GENDER: 'gender',
    WL_MONTHLY_SALARY: 'monthly_salary',
} as const;

export const QUERY_PARAMS = {
    ...UTM_PARAMS,
    ...INT_PARAMS,
    ...PARTNER_PARAMS,
    ...FORM_PARAMS,
    ...WHITELABEL_PARAMS,
    /** Активирует маркетплейс */
    MPL_ONLY: 'mplOnly',
    LOCATION: 'location',
    ANCHOR_TO_FORM: 'anchorToForm',
    PRODUCT_TYPE: 'productType',
    PRODUCT_TYPES: 'productTypes',
    PRODUCT_ID: 'productId',
    /** CF-1562 Для определения автризации через Госуслуги */
    ESIA: 'esia',
    SKIP_LANDING: 'skipLanding',
    ORGANIZATION_ID: 'organizationId',
    LOCATION_GAR: 'locationGar',
    CONVERSATION_ID: 'conversationId',
    USER_ID: 'userId',
} as const;

export const QUERY_PARTNER_KEYS = [
    QUERY_PARAMS.PARTNER_KEY,
    QUERY_PARAMS.PARTNER_OFFER_ID,
    QUERY_PARAMS.PARTNER_AFF_OFFER_ID,
    QUERY_PARAMS.SOURCE,
    QUERY_PARAMS.AFF_SOURCE,
    QUERY_PARAMS.AFF_SUB,
    QUERY_PARAMS.AFF_SUB2,
    QUERY_PARAMS.AFF_SUB3,
    QUERY_PARAMS.AFF_SUB4,
    QUERY_PARAMS.AFF_SUB5,
    QUERY_PARAMS.TRANSACTION_ID,
    QUERY_PARAMS.AGENT_ID,
];

export const MAP_FORM_FIELDS_TO_QUERY = {
    [FORM_FIELDS.AMOUNT]: QUERY_PARAMS.AMOUNT,
    [FORM_FIELDS.PERIOD]: QUERY_PARAMS.PERIOD,
    [FORM_FIELDS.PURPOSE]: QUERY_PARAMS.PURPOSE,
    [FORM_FIELDS.PRODUCT_TYPES]: QUERY_PARAMS.PRODUCT_TYPES,
};

export const WHITELABEL_QUERY_PARAMS_MAP = {
    [FORM_FIELDS.PURPOSE]: QUERY_PARAMS.WL_PURPOSE,
    [FORM_FIELDS.AMOUNT]: QUERY_PARAMS.WL_AMOUNT,
    [FORM_FIELDS.PERIOD]: QUERY_PARAMS.WL_PERIOD,
    [FORM_FIELDS.PHONE]: QUERY_PARAMS.WL_PHONE,
    [FORM_FIELDS.EMAIL]: QUERY_PARAMS.WL_EMAIL,
    [FORM_FIELDS.INITIAL_FEE]: QUERY_PARAMS.WL_INITIAL_FEE,
    [FORM_FIELDS.FIRST_NAME]: QUERY_PARAMS.WL_FIRST_NAME,
    [FORM_FIELDS.LAST_NAME]: QUERY_PARAMS.WL_LAST_NAME,
    [FORM_FIELDS.MIDDLE_NAME]: QUERY_PARAMS.WL_PATRONYMIC,
    [FORM_FIELDS.GENDER]: QUERY_PARAMS.WL_GENDER,
    [FORM_FIELDS.MONTHLY_SALARY]: QUERY_PARAMS.WL_MONTHLY_SALARY,
    [FORM_FIELDS.PARTNER_ID]: QUERY_PARAMS.PARTNER_KEY,
} as const;

export const QUERY_PARTNER_MAP_TO_FB = {
    transaction_id: 'aff_tid',
    offer_id: 'aff_offer_id',
    source: 'aff_source',
} as const;
