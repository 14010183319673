import { createAction, createReducer } from 'redux-act';
import { useSelector } from 'react-redux';
import { UTM_MARKS, UTM_MEDIUM_VALUES } from '../../constants';
import { IGlobalState } from '../index';

export type UtmMarks = ValuesOf<typeof UTM_MARKS>;

export type IState = {
    query: Partial<Record<UtmMarks, string>>;
    cookie: Partial<Record<UtmMarks, string>>;
};

const initialState: IState = {
    query: {},
    cookie: {},
};

export const setUtmMarks = createAction<IState>('set utm marks');

export const utmMarksReducer = createReducer<IState>({}, initialState).on(setUtmMarks, (state, payload) => ({
    ...state,
    ...payload,
}));

const isMarkValueNotSet = (name: UtmMarks, value: unknown) => {
    if (UTM_MARKS.MEDIUM === name) {
        return value === '(none)';
    }
    return value === '(not set)';
};

export const marksSelector = (state: IGlobalState['utmMarks']) => {
    return Object.values(UTM_MARKS).reduce(
        (prev, name) => {
            const markName = name as UtmMarks;

            const queryValue = state.query[markName];
            if (queryValue && !isMarkValueNotSet(markName, queryValue)) {
                prev[markName] = queryValue;
                return prev;
            }

            const cookieValue = state.cookie[markName];
            if (cookieValue && !isMarkValueNotSet(markName, cookieValue)) {
                prev[markName] = cookieValue;
                return prev;
            }

            prev[markName] = markName === UTM_MARKS.MEDIUM ? '(none)' : '(not set)';
            return prev;
        },
        {} as Record<UtmMarks, string>,
    );
};

export function useUtmMarks() {
    return useSelector((state: IGlobalState) => marksSelector(state.utmMarks));
}

export function useIsPaidTraffic() {
    return useSelector((state: IGlobalState) => {
        const currentMarks = marksSelector(state.utmMarks);
        return UTM_MEDIUM_VALUES.PAID_TRAFFIC.includes(currentMarks[UTM_MARKS.MEDIUM]);
    });
}
